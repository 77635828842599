import { Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import intl from '../../helper/intl'
import utils from '../../helper/utils'

import { useSelector } from 'react-redux'

import useStyles from './AppFooter.styles'

const AppFooter = () => {
  const accountId = useSelector((state) => state.auth.accountId)
  const accountDetailsData = useSelector((state) => state.accountDetails.details)
  const urlBasename = useSelector((state) => state.intl.urlBasename)
  const classes = useStyles()

  const menuItems = utils.getMenuItems(urlBasename, accountId, accountDetailsData.isFromSSO)

  return (
    <footer className={classes.wrapper}>
      <ul className={classes.menu}>
        {menuItems.map((menuItem, index) =>
          menuItem.footer ? (
            <li key={menuItem.id} className={classes['menu-item']}>
              <Link component={RouterLink} to={menuItem.url} color={window.app.theme.palette.link ? 'link.main' : 'primary'}>
                {intl.translate(menuItem.name)}
              </Link>
            </li>
          ) : null
        )}
      </ul>
    </footer>
  )
}

export default AppFooter
