import { makeStyles } from '@mui/styles'

const drawerWidth = 285

const useStyles = makeStyles(
  (theme) => ({
    'header-spacing': theme.mixins.toolbar,
    drawer: {
      width: drawerWidth,
      whiteSpace: 'nowrap',
      flexShrink: '0',

      [theme.breakpoints.down('md')]: {
        position: 'absolute'
      }
    },
    'drawer-open': {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    'drawer-closed': {
      width: theme.spacing(7),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      [theme.breakpoints.down('md')]: {
        width: 0
      }
    },
    'drawer-overlay': {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      zIndex: -1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      // Remove grey highlight
      WebkitTapHighlightColor: 'transparent',
      // Disable scroll capabilities.
      touchAction: 'none',
      opacity: 0,
      transition: theme.transitions.create(['zIndex', 'opacity'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    'drawer-overlay-active': {
      [theme.breakpoints.down('md')]: {
        zIndex: theme.zIndex.drawer - 1,
        opacity: 1
      }
    },
    'drawer-list-wrapper': {
      position: 'relative'
    },
    'drawer-list': {
      position: 'absolute',
      width: drawerWidth - 1,
    },
    item: {
      color: 'inherit'
    },
    'item-text': {
      whiteSpace: 'normal',

      '& .MuiTypography-root': {
        lineHeight: 1,        
      }
    }
  }),
  { name: 'navigation-menu-' }
)

export default useStyles
