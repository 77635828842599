import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => {
    const toolbarMixin = theme.mixins.toolbar

    const toolbarLogoMixin = {
      // The logo will take 3/4 of the height of the header
      maxHeight: (toolbarMixin.minHeight * 3) / 4,
      maxWidth: (toolbarMixin.minHeight * 3) / 3
    }

    for (const key in toolbarMixin) {
      if (key && toolbarMixin[key] && toolbarMixin[key].minHeight) {
        toolbarLogoMixin[key] = {
          maxHeight: (toolbarMixin[key].minHeight * 3) / 4,
          maxWidth: (toolbarMixin[key].minHeight * 3) / 3
        }
      }
    }

    return {
      wrapper: {
        ...theme.mixins.toolbar,
        zIndex: theme.zIndex.drawer + 1
      },
      'menu-button': {
        color: 'inherit'
      },
      title: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
          paddingRight: theme.spacing(1),
          paddingLeft: theme.spacing(0.5)
        }
      },
      'title-text': {
        paddingLeft: theme.spacing(2),
        flex: 1,
        [theme.breakpoints.down('md')]: {
          paddingLeft: theme.spacing(1),
          fontSize: '90%'
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '85%'
        }
      },
      logo: {
        ...toolbarLogoMixin,
        fontSize: '60%',

        // Rules for accommodating rectangular logos on desktop and tablet (on mobile we still restrict the width)
        [theme.breakpoints.up('md')]: {
          maxWidth: theme.mainLogoMaxWidthDesktop || 125
        },
        [theme.breakpoints.only('sm')]: {
          maxWidth: theme.mainLogoMaxWidthTablet || 100
        }
      },
      'language-select': {
        width: '90px',
        '& .MuiOutlinedInput-root': {
          backgroundColor: theme.palette.background.paper
        },
        '& .MuiNativeSelect-select': {
          paddingTop: '10.5px',
          paddingBottom: '10.5px'
        },
        '& .language-select-html-value': {
          top: '10.5px',
          bottom: '10.5px',
          color: theme.palette.text.primary,

          'body[dir="rtl"] &': {
            // Edge case caused by this input used in ltr mode in a rtl language
            right: '10.5px'
          }
        }
      }
    }
  },
  { name: 'app-header-' }
)

export default useStyles
