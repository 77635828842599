import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      position: 'relative',
      display: 'inline-flex'
    },
    button: {
      width: '100%'
    },
    progress: {
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      margin: 'auto'
    }
  }),
  { name: 'button-with-progress-' }
)

export default useStyles
