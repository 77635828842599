import { Button } from '@mui/material'

import NativeSelectInput from '../NativeSelectInput'
import TextInput from '../TextInput'

import intl from '../../../helper/intl'

import countryCodeOptions from '../../../data/country_codes.json'

import useStyles from './PhoneNumberInput.styles'

const PhoneNumberInput = ({
  id,
  label,
  disabled,
  variant,
  margin,
  confirmed,
  onConfirm,
  value,
  countryCodeValue,
  onPhoneNumberChange,
  onCountryCodeChange,
  ...otherProperties
}) => {
  const classes = useStyles()

  const getPhoneNumberMask = (countryCode) => {
    if (countryCode === 'CA' || countryCode === 'US') {
      return '(999) 999-9999'
    }

    // The default phone number format is just numbers, with a maximum length of 20 numbers.
    return '99999999999999999999'
  }

  const getPhoneNumberMaskChar = (countryCode) => {
    if (countryCode === 'CA' || countryCode === 'US') {
      return '_'
    }

    return ''
  }

  const getNumericValue = () => {
    return value.replace(/\D/g, '') // remove non-numeric characters
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes['country-code-wrapper']}>
        <NativeSelectInput
          id={id + '--country-code'}
          label={intl.translate('general__country_prefix_label')}
          disabled={disabled}
          name={id + '--country-code'}
          value={countryCodeValue}
          renderValue={
            (value) =>
              value
                ? intl.getDialCodeByCountryCode(value) || '–'
                : '' /* The selected value contains the country code, but we want to show the dial code */
          }
          options={countryCodeOptions}
          getOptionValue={(option) => option.code}
          renderOption={(option) => option.name + (option.dial_code ? ' (' + option.dial_code + ')' : '')}
          variant={typeof variant !== 'undefined' ? variant : 'outlined'}
          margin={typeof margin !== 'undefined' ? margin : 'normal'}
          fullWidth={true}
          // dir={'ltr' /* Numbers shouldn't change in between LTR and RTL languages, so we always keep this input LTR */}
          onChange={onCountryCodeChange}
        />
      </div>
      <div className={classes['phone-number-wrapper']}>
        <TextInput
          type="tel"
          id={id + '--phone-number'}
          label={label}
          disabled={disabled}
          value={value}
          mask={getPhoneNumberMask(countryCodeValue)}
          maskChar={getPhoneNumberMaskChar(countryCodeValue)}
          onChange={onPhoneNumberChange}
          variant={typeof variant !== 'undefined' ? variant : 'outlined'}
          margin={typeof margin !== 'undefined' ? margin : 'normal'}
          fullWidth={true}
          {...otherProperties}
        />
      </div>
      {confirmed === false && getNumericValue() && (
        <div className={classes['confirm-button-wrapper']}>
          <Button variant="text" color={window.app.theme.palette.button ? 'button' : 'primary'} size="small" onClick={onConfirm}>
            {intl.translate('general__verify')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default PhoneNumberInput
