import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      '& .MuiSnackbarContent-root': {
        // we need snackbar background color to be darker to meet color contrast requirements
        backgroundColor: theme.palette.grey[theme.palette.type === 'dark' ? '50' : '900']
      }
    },
    'action-btn': {
      color: theme.palette.secondary.light
    }
  }),
  { name: 'basic-snackbar-' }
)

export default useStyles
