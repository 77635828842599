import { useEffect, useRef } from 'react'
import { Paper, Button, Typography } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { visuallyHidden } from '@mui/utils'

import PageTitle from '../common/PageTitle'
import ProgressOverlay from '../common/ProgressOverlay'
import LocationFieldset from './LocationFieldset'
import ButtonWithProgress from '../common/ButtonWithProgress'
import BasicDialog from '../common/BasicDialog'

import { useSelector, useDispatch } from 'react-redux'
import {
  accountLocationsChange,
  updateLocationAsync,
  deleteLocationAsync,
  showNewLocationDialog,
  hideNewLocationDialog,
  newLocationChange,
  createNewLocationAsync
} from '../../actions/accountLocationsAction'
import { openAppDialog, closeAppDialog, openAppSnackbar, closeAppSnackbar } from '../../actions/notificationsAction'

import useStyles from './AccountLocationsPage.styles'
import intl from '../../helper/intl'

const AccountLocationsPage = () => {
  const companyData = useSelector((state) => state.auth.companyData)
  const accountId = useSelector((state) => state.auth.accountId)
  const locations = useSelector((state) => state.accountLocations.locations)
  const locationsLoading = useSelector((state) => state.accountLocations.locationsLoading)
  const updateLocationLoading = useSelector((state) => state.accountLocations.updateLocationLoading)
  const deleteLocationLoading = useSelector((state) => state.accountLocations.deleteLocationLoading)
  const newLocationDialogIsOpen = useSelector((state) => state.accountLocations.newLocationDialogIsOpen)
  const newLocation = useSelector((state) => state.accountLocations.newLocation)
  const createNewLocationLoading = useSelector((state) => state.accountLocations.createNewLocationLoading)
  const dispatch = useDispatch()
  const classes = useStyles()
  // const [errorButtons, setErrorButtons] = useState(false)
  const locationsRef = useRef(locations)
  locationsRef.current = locations
  useEffect(() => {
    document.title =
      intl.translate('account_locations_page__title') + ' | ' + (companyData.portal_title || intl.translate('app_header__title'))
  }, [companyData])

  const shouldFieldsetBeDisabled = (locationId) => {
    // We'll disable a fieldset if the location of that fieldset is being updated or deleted.
    return (
      (updateLocationLoading && updateLocationLoading === locationId) || (deleteLocationLoading && deleteLocationLoading === locationId)
    )
  }

  const onLocationsChange = (locationId, location) => {
    const locations = locationsRef.current
    const newLocations = []
    const numLocations = locations.length
    for (let i = 0; i < numLocations; i++) {
      const _location = locationId === locations[i].id ? location : locations[i]
      newLocations.push({ ..._location })
    }
    dispatch(accountLocationsChange({ locations: newLocations }))
  }

  const onUpdateLocationFormSubmit = (event, location) => {
    event.preventDefault()
    // If latitude and longitude are not included, show a message to geolocate
    if (!location.longitude || !location.latitude) {
      dispatch(
        openAppSnackbar({
          message: intl.translate('account_locations_page__create_error_geolocate'),
          actionText: intl.translate('account_locations_page__geolocate_btn'),
          actionFunction: () => {
            dispatch(closeAppSnackbar())
            document.getElementById('location-fieldset-' + location.id + '--geolocate-btn').click()
          }
        })
      )
    } else {
      dispatch(updateLocationAsync({ accountId, location }))
    }
  }

  const showDeleteLocationDialog = (locationId) => {
    dispatch(
      openAppDialog({
        title: intl.translate('account_locations_page__delete_modal_title'),
        description: intl.translate('account_locations_page__delete_modal_description'),
        actionText: intl.translate('account_locations_page__delete_modal_confirm'),
        actionFunction: () => onDeleteLocation(locationId),
        autoFocusButton: 'cancel'
      })
    )
  }

  const onDeleteLocation = (locationId) => {
    dispatch(closeAppDialog())
    dispatch(deleteLocationAsync({ accountId, id: locationId }))
  }

  const onCreateLocationFormSubmit = (event) => {
    event.preventDefault()
    // If latitude and longitude are not included, show a message to geolocate
    if (!newLocation.longitude || !newLocation.latitude) {
      dispatch(
        openAppSnackbar({
          message: (
            <span>
              {intl.translate('account_locations_page__create_error_geolocate')}{' '}
              <Typography style={visuallyHidden}>{intl.translate('account_locations_page__create_prompt_geolocate')}</Typography>
            </span>
          ),
          actionText: intl.translate('account_locations_page__geolocate_btn'),
          actionFunction: () => {
            dispatch(closeAppSnackbar())
            document.getElementById('location-fieldset-new-location--geolocate-btn').click()
          }
        })
      )
    } else {
      dispatch(createNewLocationAsync({ accountId, location: newLocation }))
    }
  }

  return (
    <>
      <Paper className={'_centered_container_ _position_relative_ ' + classes.wrapper}>
        <ProgressOverlay hidden={!locationsLoading} />

        <PageTitle>{intl.translate('account_locations_page__title')}</PageTitle>

        {locations.map((location, index) => (
          <form
            key={index}
            className={classes['location-form']}
            autoComplete="off"
            onSubmit={(event) => onUpdateLocationFormSubmit(event, location)}
          >
            <LocationFieldset
              id={location.id || index}
              className={classes['location-fieldset']}
              legend={location.name || '-'}
              disabled={shouldFieldsetBeDisabled(location.id)}
              name={location.name}
              line1={location.line1}
              line2={location.line2}
              city={location.city}
              postcode={location.postcode}
              state={location.state}
              country={location.country}
              longitude={location.longitude}
              latitude={location.latitude}
              onChange={(location) => onLocationsChange(location.id, location)}
              onDelete={() => showDeleteLocationDialog(location.id)}
              showDeleteProgress={deleteLocationLoading && deleteLocationLoading === location.id}
            />

            <div className="_centered_btn_container_">
              <ButtonWithProgress
                type="submit"
                className={classes['update-btn']}
                showProgress={updateLocationLoading && updateLocationLoading === location.id}
                disabled={shouldFieldsetBeDisabled(location.id)}
                variant="contained"
                color={window.app.theme.palette.button ? 'button' : 'primary'}
                size="large"
              >
                {intl.translate('account_locations_page__update_btn')}
                <Typography style={visuallyHidden}> {location.name}</Typography>
              </ButtonWithProgress>
            </div>
          </form>
        ))}

        <div className="_centered_btn_container_">
          <Button
            className={classes['create-btn']}
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<AddIcon />}
            onClick={() => dispatch(showNewLocationDialog())}
          >
            {intl.translate('account_locations_page__create_btn')}
          </Button>
        </div>
      </Paper>

      <BasicDialog
        id="new-location-dialog"
        isOpen={newLocationDialogIsOpen}
        title={intl.translate('account_locations_page__create_modal_title')}
        description=""
        actionButtons={[
          {
            content: intl.translate('account_locations_page__create_modal_cancel'),
            onClick: () => dispatch(hideNewLocationDialog())
          },
          {
            content: intl.translate('account_locations_page__create_modal_confirm'),
            type: 'submit',
            form: 'new-location-form',
            showProgress: createNewLocationLoading
          }
        ]}
        onClose={() => dispatch(hideNewLocationDialog())}
        maxWidth="md"
      >
        <form id="new-location-form" autoComplete="off" onSubmit={onCreateLocationFormSubmit}>
          <LocationFieldset
            id="new-location"
            legend={newLocation.name || '-'}
            autoFocus={true}
            disabled={createNewLocationLoading}
            name={newLocation.name}
            line1={newLocation.line1}
            line2={newLocation.line2}
            city={newLocation.city}
            postcode={newLocation.postcode}
            state={newLocation.state}
            country={newLocation.country}
            latitude={newLocation.latitude}
            longitude={newLocation.longitude}
            onChange={(location) => dispatch(newLocationChange({ location }))}
          />
        </form>
      </BasicDialog>
    </>
  )
}

export default AccountLocationsPage
