import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      position: 'relative',
      width: '100%',
      overflow: 'hidden', // Prevent long labels from overflowing the input's limits

      '& .MuiInputBase-root.Mui-error input': {
        paddingRight: theme.spacing(6)
      }
    },
    'error-icon': {
      position: 'absolute',
      top: theme.spacing(2),
      right: 0,
      margin: '16px 14px',
      color: theme.palette.error.main
    },
    'error-icon-hidden': {
      display: 'none'
    }
  }),
  { name: 'text-input-' }
)

export default useStyles
