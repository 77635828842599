import { Snackbar, IconButton, Button } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'

import { useSelector } from 'react-redux'

import useStyles from './BasicSnackbar.styles'

const BasicSnackbar = ({ id, isOpen, children, message, duration, ignoreClickAway, actionButton, onClose }) => {
  const languageDirection = useSelector((state) => state.intl.languageDirection)
  const classes = useStyles()

  // By default, snackbars won't hide automatically
  // Recommended duration for autohide by Material Design is between 4s and 10s
  const defaultDuration = null

  const handleSnackbarClose = (event, reason) => {
    if (!ignoreClickAway || reason !== 'clickaway') {
      if (typeof onClose === 'function') {
        onClose(event, reason)
      }
    }
  }

  // Renders the action buttons of the snackbar.
  // Snackbars in our app will always have a close button (IconButton), and optionally an action button
  const renderSnackbarActions = (_actionButton) => {
    const snackbarActions = []

    if (_actionButton) {
      snackbarActions.push(
        <Button
          key="action"
          id={id + '--action-btn'}
          className={classes['action-btn']}
          variant="text"
          color="secondary"
          onClick={_actionButton.onClick}
        >
          {_actionButton.content}
        </Button>
      )
    }

    snackbarActions.push(
      <IconButton
        key="close"
        id={id + '--close-btn'}
        aria-label="close"
        color="inherit"
        onClick={(event) => handleSnackbarClose(event, 'buttonclick')}
      >
        <CloseIcon />
      </IconButton>
    )

    return snackbarActions
  }

  return (
    <Snackbar
      id={id}
      className={classes.wrapper}
      tabIndex={'-1' /* Makes the snackbar content focusable via script */}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      dir={languageDirection}
      open={isOpen}
      autoHideDuration={typeof duration !== 'undefined' ? duration : defaultDuration}
      onClose={handleSnackbarClose}
      message={<span id={id + '--message'}>{message}</span>}
      ContentProps={{ 'aria-labelledby': id + '--message', 'aria-live': 'polite' }}
      action={renderSnackbarActions(actionButton)}
    >{children}</Snackbar>
  )
}

export default BasicSnackbar
