import { useEffect } from 'react'
import { Paper, Typography } from '@mui/material'
import PageTitle from '../common/PageTitle'
import TextInput from '../common/TextInput'
import ButtonWithProgress from '../common/ButtonWithProgress'

import authService from '../../service/authService'
import intl from '../../helper/intl'

import { useSelector, useDispatch } from 'react-redux'
import { deregisterStart, deregisterSuccess, deregisterFailure, deregisterCredentialsChange } from '../../actions/authAction'
import { openAppSnackbar, closeAppSnackbar } from '../../actions/notificationsAction'

import useStyles from './AccountDeregisterPage.styles'

const AccountDeregisterPage = () => {
  const companyData = useSelector((state) => state.auth.companyData)
  const accountId = useSelector((state) => state.auth.accountId)
  const accountDetails = useSelector((state) => state.accountDetails.details)
  const deregisterLoading = useSelector((state) => state.auth.deregisterLoading)
  const deregisterCredentials = useSelector((state) => state.auth.deregisterCredentials)
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    document.title = intl.translate('account_deregister_page__title') + ' | ' + (companyData.portal_title || intl.translate('app_header__title'))
  }, [companyData])

  const onDeregisterFormSubmit = (event) => {
    event.preventDefault()

    dispatch(deregisterStart())
    authService
      .deregister({
        accountId,
        email: accountDetails.email,
        phoneNumber: accountDetails.phoneNumber,
        countryCode: accountDetails.countryCode,
        externalId: accountDetails.externalId,
        password: deregisterCredentials.password,
        isFromSSO: accountDetails.isFromSSO
      })
      .then((result) => {
        dispatch(deregisterSuccess())
        window.location.reload()
      })
      .catch((error) => {
        dispatch(deregisterFailure({ error: intl.translate('account_deregister_page__deregister_error') }))
        dispatch(
          openAppSnackbar({
            message: intl.getServerError(error.response.data) || intl.translate('account_deregister_page__deregister_error'),
            actionText: intl.translate('general__retry'),
            actionFunction: () => {
              dispatch(closeAppSnackbar())
              onDeregisterFormSubmit(event)
            }
          })
        )
      })
  }

  return (
    <Paper className="_centered_container_">
      <PageTitle>{intl.translate('account_deregister_page__title')}</PageTitle>

      <form className={classes.form} autoComplete="off" onSubmit={onDeregisterFormSubmit}>
        <Typography variant="body1" paragraph>
          {accountDetails.isFromSSO
            ? intl.translate('account_deregister_page__security_message_SSO')
            : intl.translate('account_deregister_page__security_message')}
        </Typography>

        <TextInput
          className={accountDetails.isFromSSO ? '_hidden_' : ''}
          id="account-deregister-page--password"
          type="password"
          label={intl.translate('general__password_label')}
          disabled={deregisterLoading}
          value={deregisterCredentials.password}
          onChange={(event) => dispatch(deregisterCredentialsChange({ credentials: { password: event.target.value } }))}
        />

        <div className={'_centered_btn_container_ ' + classes['submit-btn-wrapper']}>
          <ButtonWithProgress
            type="submit"
            showProgress={deregisterLoading}
            variant="contained"
            color={window.app.theme.palette.button ? 'button' : 'primary'}
            size="large"
          >
            {intl.translate('account_deregister_page__deregister_btn')}
          </ButtonWithProgress>
        </div>
      </form>
    </Paper>
  )
}

export default AccountDeregisterPage
