import BasicSnackbar from '../common/BasicSnackbar'
import BasicDialog from '../common/BasicDialog'
import ContactConfirmationDialog from '../ContactConfirmationDialog'
import PasswordResetDialog from '../PasswordResetDialog/PasswordResetDialog'

import intl from '../../helper/intl'

import { useSelector, useDispatch } from 'react-redux'
import { closeAppSnackbar, closeAppDialog, closeContactConfirmationDialog } from '../../actions/notificationsAction'

const AppNotifications = () => {
  const appSnackbarIsOpen = useSelector((state) => state.notifications.appSnackbarIsOpen)
  const appSnackbarMessage = useSelector((state) => state.notifications.appSnackbarMessage)
  const appSnackbarDuration = useSelector((state) => state.notifications.appSnackbarDuration)
  const appSnackbarActionText = useSelector((state) => state.notifications.appSnackbarActionText)
  const appSnackbarActionFunction = useSelector((state) => state.notifications.appSnackbarActionFunction)

  const appDialogIsOpen = useSelector((state) => state.notifications.appDialogIsOpen)
  const appDialogTitle = useSelector((state) => state.notifications.appDialogTitle)
  const appDialogDescription = useSelector((state) => state.notifications.appDialogDescription)
  const appDialogActionText = useSelector((state) => state.notifications.appDialogActionText)
  const appDialogActionFunction = useSelector((state) => state.notifications.appDialogActionFunction)
  const appDialogAutoFocusButton = useSelector((state) => state.notifications.appDialogAutoFocusButton)

  const contactConfirmationDialogIsOpen = useSelector((state) => state.notifications.contactConfirmationDialogIsOpen)
  const contactConfirmationDialogEmail = useSelector((state) => state.notifications.contactConfirmationDialogEmail)
  const contactConfirmationDialogPhoneNumber = useSelector((state) => state.notifications.contactConfirmationDialogPhoneNumber)

  const accountDetails = useSelector((state) => state.accountDetails.details)

  const dispatch = useDispatch()

  // useEffect(() => {
  //   if (appSnackbarIsOpen) {
  //     // focus on the app snackbar right after it opens. This helps keyboard users navigate the site.
  //     document.getElementById('app-snackbar').focus();
  //   }
  // }, [appSnackbarIsOpen]);

  const generateAppDialogButtons = () => {
    const appDialogActionButtons = [
      {
        id: 'app-dialog--cancel-btn',
        content: intl.translate('general__cancel'),
        autoFocus: appDialogAutoFocusButton === 'cancel',
        onClick: () => dispatch(closeAppDialog())
      }
    ]

    if (appDialogActionText && appDialogActionFunction) {
      appDialogActionButtons.push({
        id: 'app-dialog--action-btn',
        content: appDialogActionText,
        autoFocus: appDialogAutoFocusButton === 'action',
        onClick: appDialogActionFunction
      })
    }

    return appDialogActionButtons
  }

  return (
    <>
      {/* App snackbar - Shows temporary messages to provide feedback to the user, and dissapears automatically */}
      <BasicSnackbar
        id="app-snackbar"
        isOpen={appSnackbarIsOpen}
        message={appSnackbarMessage}
        duration={appSnackbarDuration}
        ignoreClickAway={true}
        actionButton={
          appSnackbarActionText && appSnackbarActionFunction && { content: appSnackbarActionText, onClick: appSnackbarActionFunction }
        }
        onClose={() => dispatch(closeAppSnackbar())}
      />

      {/* App dialog - Interrupts the user to show an important notification that they have to acknowledge */}
      <BasicDialog
        id="app-dialog"
        isOpen={appDialogIsOpen}
        title={appDialogTitle}
        description={appDialogDescription}
        actionButtons={generateAppDialogButtons()}
        onClose={() => dispatch(closeAppDialog())}
      />

      {/* Contact confirmation dialog - Used to confirm emails and phone numbers */}
      <ContactConfirmationDialog
        email={contactConfirmationDialogEmail}
        phoneNumber={contactConfirmationDialogPhoneNumber}
        isOpen={contactConfirmationDialogIsOpen}
        onClose={() => dispatch(closeContactConfirmationDialog())}
      />

      {/* Password reset dialog - Used to reset the password if passwordUpdateNeeded = true */}
      <PasswordResetDialog
        isOpen={accountDetails.passwordUpdateNeeded}
      />
    </>
  )
}

export default AppNotifications
