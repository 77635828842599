import { useEffect } from 'react'
import { Paper, Button, Typography } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { visuallyHidden } from '@mui/utils'

import PageTitle from '../common/PageTitle'
import ProgressOverlay from '../common/ProgressOverlay'
import PhoneFieldset from './PhoneFieldset'
import ButtonWithProgress from '../common/ButtonWithProgress'
import BasicDialog from '../common/BasicDialog'

import intl from '../../helper/intl'
import validate from '../../helper/validate'

import accountService from '../../service/accountService'

import { useSelector, useDispatch } from 'react-redux'
import {
  accountPhonesChange,
  updatePhoneStart,
  updatePhoneSuccess,
  updatePhoneFailure,
  deletePhoneAsync,
  showNewPhoneDialog,
  hideNewPhoneDialog,
  newPhoneChange,
  createNewPhoneAsync
} from '../../actions/accountPhonesAction'
import {
  openAppSnackbar,
  closeAppSnackbar,
  openAppDialog,
  closeAppDialog,
  openContactConfirmationDialog
} from '../../actions/notificationsAction'

import useStyles from './AccountPhonesPage.styles'

const AccountPhonesPage = () => {
  const companyData = useSelector((state) => state.auth.companyData)
  const accountId = useSelector((state) => state.auth.accountId)
  const loginCredentials = useSelector((state) => state.auth.loginCredentials)
  const phones = useSelector((state) => state.accountPhones.phones)
  const phonesLoading = useSelector((state) => state.accountPhones.phonesLoading)
  const newPhone = useSelector((state) => state.accountPhones.newPhone)
  const updatePhoneLoading = useSelector((state) => state.accountPhones.updatePhoneLoading)
  const deletePhoneLoading = useSelector((state) => state.accountPhones.deletePhoneLoading)
  const newPhoneDialogIsOpen = useSelector((state) => state.accountPhones.newPhoneDialogIsOpen)
  const createNewPhoneLoading = useSelector((state) => state.accountPhones.createNewPhoneLoading)
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    document.title = intl.translate('account_phones_page__title') + ' | ' + (companyData.portal_title || intl.translate('app_header__title'))
  }, [companyData])

  const shouldFieldsetBeDisabled = (phoneId) => {
    // We'll disable a fieldset if the phone of that fieldset is being updated or deleted.
    return (updatePhoneLoading && updatePhoneLoading === phoneId) || (deletePhoneLoading && deletePhoneLoading === phoneId)
  }

  const onPhonesChange = (phoneId, phone) => {
    const newPhones = []
    const numPhones = phones.length
    for (let i = 0; i < numPhones; i++) {
      const _phone = phoneId === phones[i].id ? phone : phones[i]
      const _phoneInput = document.getElementById('phone-fieldset-' + _phone.id + '--phone-number--phone-number')
      if (_phoneInput && _phone.phoneNumber !== _phoneInput.value) {
        // To prevent showing errors on valid phone inputs, we have to pick the value from the HTML field
        _phone.phoneNumber = _phoneInput.value
      }
      newPhones.push({ ..._phone })
    }
    dispatch(accountPhonesChange({ phones: newPhones }))
  }

  const onUpdatePhoneFormSubmit = (event, phone) => {
    event.preventDefault()

    const phoneError = getPhoneError(phone)
    if (phoneError) {
      dispatch(openAppSnackbar({ message: phoneError }))
      return false
    }

    updatePhone(accountId, phone)
  }

  const updatePhone = (_accountId, _phone) => {
    dispatch(updatePhoneStart({ id: _phone.id }))

    accountService
      .updatePhone({ accountId: _accountId, phone: _phone })
      .then((result) => {
        dispatch(updatePhoneSuccess())
        dispatch(openAppSnackbar({ message: intl.translate('account_phones_page__update_success') }))
        onPhonesChange(_phone.id, result.data)
        if (result.data.phoneNumberConfirmed === false) {
          dispatch(openContactConfirmationDialog({ phoneNumber: intl.getDialCodeByCountryCode(_phone.countryCode) + _phone.phoneNumber }))
        }
      })
      .catch((error) => {
        dispatch(updatePhoneFailure({ error: intl.translate('account_phones_page__update_error') }))
        dispatch(
          openAppSnackbar({
            message: intl.getServerError(error.response.data) || intl.translate('account_phones_page__update_error'),
            actionText: intl.translate('general__retry'),
            actionFunction: () => {
              dispatch(closeAppSnackbar())
              updatePhone(_accountId, _phone)
            }
          })
        )
      })
  }

  const showDeletePhoneDialog = (phoneId) => {
    dispatch(
      openAppDialog({
        title: intl.translate('account_phones_page__delete_modal_title'),
        description: intl.translate('account_phones_page__delete_modal_description'),
        actionText: intl.translate('account_phones_page__delete_modal_confirm'),
        actionFunction: () => onDeletePhone(phoneId),
        autoFocusButton: 'cancel'
      })
    )
  }

  const onDeletePhone = (phoneId) => {
    dispatch(closeAppDialog())
    dispatch(deletePhoneAsync({ accountId, id: phoneId }))
  }

  const onCreatePhoneFormSubmit = (event) => {
    event.preventDefault()

    const phoneError = getPhoneError(newPhone)
    if (phoneError) {
      dispatch(openAppSnackbar({ message: phoneError }))
      return false
    }

    dispatch(createNewPhoneAsync({ accountId, phone: newPhone }))
  }

  const getPhoneError = (phoneObj) => {
    if (!phoneObj.alias) {
      return intl.translate('account_phones_page__empty_alias_error')
    } else if (!phoneObj.countryCode) {
      return intl.translate('general__empty_phone_prefix_error')
    } else if (!validate.isValidPhoneNumber(phoneObj.phoneNumber, { countryCode: phoneObj.countryCode })) {
      return intl.translate('general__invalid_phone_number')
    } else {
      return null
    }
  }

  return (
    <>
      <Paper className="_centered_container_ _position_relative_">
        <ProgressOverlay hidden={!phonesLoading} />
          <PageTitle>{intl.translate('account_phones_page__title')}</PageTitle>

        {phones.map((phone, index) => (
          <form key={index} autoComplete="off" onSubmit={(event) => onUpdatePhoneFormSubmit(event, phone)}>
            <PhoneFieldset
              id={phone.id}
              className={classes['phone-fieldset']}
              legend={phone.alias || '-'}
              disabled={shouldFieldsetBeDisabled(phone.id)}
              phoneNumberConfirmed={phone.phoneNumberConfirmed}
              onConfirmPhoneNumber={() =>
                dispatch(
                  openContactConfirmationDialog({ phoneNumber: intl.getDialCodeByCountryCode(phone.countryCode) + phone.phoneNumber })
                )
              }
              alias={phone.alias}
              countryCode={phone.countryCode}
              phoneNumber={phone.phoneNumber}
              phoneNumberSms={phone.phoneNumberSms}
              onChange={(phone) => onPhonesChange(phone.id, phone)}
              onDelete={() => showDeletePhoneDialog(phone.id)}
              showDeleteProgress={deletePhoneLoading && deletePhoneLoading === phone.id}
            />

            <div className="_centered_btn_container_">
              <ButtonWithProgress
                type="submit"
                id={'update-phone-' + phone.id + '-btn'}
                className={classes['update-btn']}
                showProgress={updatePhoneLoading && updatePhoneLoading === phone.id}
                disabled={shouldFieldsetBeDisabled(phone.id)}
                variant="contained"
                color={window.app.theme.palette.button ? 'button' : 'primary'}
                size="large"
              >
                {intl.translate('account_phones_page__update_btn')}
                <Typography style={visuallyHidden}> {phone.alias}</Typography>
              </ButtonWithProgress>
            </div>
          </form>
        ))}

        <div className="_centered_btn_container_">
          <Button
            className={classes['create-btn']}
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<AddIcon />}
            onClick={() => dispatch(showNewPhoneDialog())}
          >
            {intl.translate('account_phones_page__create_btn')}
          </Button>
        </div>
      </Paper>

      <BasicDialog
        id="new-phone-dialog"
        isOpen={newPhoneDialogIsOpen}
        title={intl.translate('account_phones_page__create_modal_title')}
        description=""
        actionButtons={[
          {
            content: intl.translate('account_phones_page__create_modal_cancel'),
            onClick: () => dispatch(hideNewPhoneDialog())
          },
          {
            id: 'create-new-phone-btn',
            content: intl.translate('account_phones_page__create_modal_confirm'),
            type: 'submit',
            form: 'new-phone-form',
            showProgress: createNewPhoneLoading
          }
        ]}
        onClose={() => dispatch(hideNewPhoneDialog())}
      >
        <form id="new-phone-form" autoComplete="off" onSubmit={onCreatePhoneFormSubmit}>
          <PhoneFieldset
            id="new-phone"
            legend={newPhone.alias || '-'}
            autoFocus={true}
            disabled={createNewPhoneLoading}
            alias={newPhone.alias}
            countryCode={newPhone.countryCode || loginCredentials.countryCode}
            phoneNumber={newPhone.phoneNumber}
            phoneNumberSms={newPhone.phoneNumberSms}
            onChange={(phone) => dispatch(newPhoneChange({ phone }))}
          />
        </form>
      </BasicDialog>
    </>
  )
}

export default AccountPhonesPage
