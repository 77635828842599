import { useEffect, useState } from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import PageTitle from '../common/PageTitle'
import TextInput from '../common/TextInput'
import ButtonWithProgress from '../common/ButtonWithProgress'

import authService from '../../service/authService'
import accountService from '../../service/accountService'
import intl from '../../helper/intl'
import validate from '../../helper/validate'

import { useSelector, useDispatch } from 'react-redux'
import { openAppSnackbar, closeAppSnackbar } from '../../actions/notificationsAction'

import { accountDetailsChange, updateAccountDetailsFailure, updateAccountDetailsStart, updateAccountDetailsSuccess } from '../../actions/accountDetailsAction'

const AccountPasswordPage = () => {
  const companyData = useSelector((state) => state.auth.companyData)
  const accountId = useSelector((state) => state.auth.accountId)
  const details = useSelector((state) => state.accountDetails.details)
  const customFields = useSelector((state) => state.accountDetails.customFields)
  const updateDetailsLoading = useSelector((state) => state.accountDetails.updateDetailsLoading)
  const dispatch = useDispatch()

  const [passwordIsDirty, setPasswordIsDirty] = useState(false)
  const [newPasswordIsDirty, setNewPasswordIsDirty] = useState(false)
  const [newPasswordConfirmIsDirty, setNewPasswordConfirmIsDirty] = useState(false)

  useEffect(() => {
    document.title = intl.translate('account_password_page__title') + ' | ' + (companyData.portal_title || intl.translate('app_header__title'))
  }, [companyData]);

  const onDetailsChange = (paramName, paramValue) => {
    const newDetails = { ...details }
    newDetails[paramName] = paramValue
    dispatch(accountDetailsChange({ details: newDetails }))
  }

  const validatePasswordFields = (password, newPassword, newPasswordConfirm) => {
    const newPasswordIsValid = validate.isValidPassword(newPassword)
    const newPasswordConfirmIsValid = newPasswordConfirm === newPassword

    return newPasswordIsValid && newPasswordConfirmIsValid
  }

  const onFormSubmit = (event) => {
    event.preventDefault()

    dispatch(updateAccountDetailsStart())

    // GEM-6978: If user pretends to change password but current one was not indicated, show error
    if (details.newPassword && !details.password) {
      dispatch(updateAccountDetailsFailure({ error: intl.translate('account_details_page__update_error_password') }))
      dispatch(
        openAppSnackbar({
          message: intl.translate('account_details_page__update_error_password'),
          actionText: intl.translate('general__retry'),
          actionFunction: () => {
            dispatch(closeAppSnackbar())
            document.getElementById('account-details-page--update-btn').click()
          }
        })
      )
      return false
    }

    if (details.password) {
      // If the user filled out the password field, we need to validate it using oauth
      authService
        .oauthLogin({
          email: details.email,
          phoneNumber: details.phoneNumber,
          countryCode: details.countryCode,
          externalId: details.externalId,
          password: details.password
        })
        .then((result) => {
          // Once the password was validated, update the details
          updateDetails(event)
        })
        .catch((error) => {
          dispatch(updateAccountDetailsFailure({ error: intl.translate('account_details_page__update_error_password') }))
          dispatch(
            openAppSnackbar({
              message: intl.getServerError(error.response.data) || intl.translate('account_details_page__update_error_password'),
              actionText: intl.translate('general__retry'),
              actionFunction: () => {
                dispatch(closeAppSnackbar())
                document.getElementById('account-details-page--update-btn').click()
              }
            })
          )
        })
    }
  }

  const updateDetails = (event) => {
    dispatch(updateAccountDetailsStart())

    accountService
      .updateDetails({ accountId, details, customFields })
      .then((result) => {
        dispatch(updateAccountDetailsSuccess())
        dispatch(openAppSnackbar({ message: intl.translate('account_details_page__update_success') }))

        const newDetails = { ...details }
        
        // After updating the password, empty all password-related fields (they will be incorrect otherwise)
        newDetails.password = ''
        newDetails.newPassword = ''
        newDetails.newPasswordConfirm = ''
        setPasswordIsDirty(false);
        setNewPasswordIsDirty(false);
        setNewPasswordConfirmIsDirty(false);

        dispatch(accountDetailsChange({ details: newDetails }))
      })
      .catch((error) => {
        dispatch(updateAccountDetailsFailure({ error: intl.translate('account_details_page__update_error') }))
        dispatch(
          openAppSnackbar({
            message: intl.getServerError(error.response.data) || intl.translate('account_details_page__update_error'),
            actionText: intl.translate('general__retry'),
            actionFunction: () => {
              dispatch(closeAppSnackbar())
              document.getElementById('account-details-page--update-password-btn').click()
            }
          })
        )
      })
  }

  return (
    <Paper className="_centered_container_">
      <PageTitle>{intl.translate('account_password_page__title')}</PageTitle>

      <form onSubmit={onFormSubmit}>
        <Grid container columnSpacing={2} rowSpacing={1}>
          
          <Grid item xs={12}>
            <TextInput
              id="account-details-page--password"
              type="password"
              label={intl.translate('account_details_page__current_password')}
              disabled={updateDetailsLoading}
              autoComplete="old-password"
              value={details.password}
              onChange={(event) => onDetailsChange('password', event.target.value)}
              isDirty={passwordIsDirty}
              onBlur={() => {
                if (details.password) {
                  setPasswordIsDirty(true)
                }
              }}
              isValid={(value) => !!value}
              errorMessage={intl.translate('general__empty_password')}
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextInput
              id="account-details-page--new-password"
              type="password"
              label={intl.translate('account_details_page__new_password')}
              disabled={updateDetailsLoading}
              autoComplete="new-password"
              value={details.newPassword}
              onChange={(event) => onDetailsChange('newPassword', event.target.value)}
              isDirty={newPasswordIsDirty}
              onBlur={() => {
                if (details.newPassword) {
                  setNewPasswordIsDirty(true)
                }
              }}
              isValid={validate.isValidPassword}
              errorMessage={validate.getInvalidPasswordError()}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id="account-details-page--new-password-confirm"
              type="password"
              label={intl.translate('account_details_page__new_password_confirm')}
              disabled={updateDetailsLoading}
              autoComplete="new-password-confirm"
              value={details.newPasswordConfirm}
              onChange={(event) => onDetailsChange('newPasswordConfirm', event.target.value)}
              isDirty={newPasswordConfirmIsDirty}
              onBlur={() => {
                if (details.newPasswordConfirm) {
                  setNewPasswordConfirmIsDirty(true)
                }
              }}
              isValid={(value) => value === details.newPassword}
              errorMessage={intl.translate('general__invalid_password_confirm')}
            />
          </Grid>
          <Grid item xs={12}>
            <div className="_centered_btn_container_">
              <ButtonWithProgress
                type="submit"
                id="account-details-page--update-password-btn"
                showProgress={updateDetailsLoading}
                disabled={!validatePasswordFields(details.password, details.newPassword, details.newPasswordConfirm)}
                variant="contained"
                color={window.app.theme.palette.button ? 'button' : 'primary'}
                size="large"
              >
                {intl.translate('account_details_page__submit_btn')}
                <Typography style={visuallyHidden}> {intl.translate('account_details_page__password')}</Typography>
              </ButtonWithProgress>
            </div>
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
}

export default AccountPasswordPage
