import { FormControlLabel, Checkbox } from '@mui/material'

const CheckboxInput = ({ id, label, disabled, value, checked, onChange, onClick }) => {
  return (
    <FormControlLabel
      control={<Checkbox id={id} color="secondary" checked={checked} onChange={onChange} onClick={onClick} value={value} />}
      label={label}
      disabled={disabled}
    />
  )
}

export default CheckboxInput
