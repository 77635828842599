import axios from 'axios'

import utils from '../helper/utils'

// const nominatimApiUrl = 'https://nominatim.openstreetmap.org'
const googleMapsApiUrl = 'https://maps.googleapis.com'
export const googleMapsApiKey = 'AIzaSyBIJymUhZLfQNNds5zZ6JsEz-tgLfN8qD4'

const locationService = (() => {
  /* const nominatimGeocode = ({ line1, line2, city, state, postcode, country }) => {
    let query = ''

    if (line1) {
      query += '&street=' + utils.encodeUrlParam(line1)
    }
    if (city) {
      query += '&city=' + utils.encodeUrlParam(city)
    }
    if (postcode) {
      query += '&postalcode=' + utils.encodeUrlParam(postcode)
    }
    // if (state) {
    //   query += '&state=' + utils.encodeUrlParam(state);
    // }
    if (country) {
      query += '&country=' + utils.encodeUrlParam(country)
    }

    return axios.get(nominatimApiUrl + '/search?format=json' + query)
    .then(result => {
      const match = result.data && result.data.length ? result.data[0] : null

      if (match) {
        result.location = { longitude: match.lon, latitude: match.lat }
      }

      return result
    })
  } */

  const googleMapsGeocode = ({ line1, line2, city, state, postcode, country }) => {
    let query = '&address='

    if (line1) {
      query += utils.encodeUrlParam(line1)
    }
    if (city) {
      query += utils.encodeUrlParam(', ' + city)
    }
    if (state) {
      query += utils.encodeUrlParam(', ' + state)
    }
    if (postcode) {
      query += utils.encodeUrlParam(', ' + postcode)
    }
    if (country) {
      query += utils.encodeUrlParam(', ' + country)
    }

    return axios.get(googleMapsApiUrl + '/maps/api/geocode/json?key=' + googleMapsApiKey + query).then((result) => {
      const match = result.data && result.data.results && result.data.results.length ? result.data.results[0] : null
      result.location = parseGoogleMapsLocation(match)
      return result
    })
  }

  const googleMapsReverseGeocode = ({ longitude, latitude }) => {
    let query = ''

    if (longitude && latitude) {
      query += '&latlng=' + latitude + ',' + longitude
    }

    return axios.get(googleMapsApiUrl + '/maps/api/geocode/json?key=' + googleMapsApiKey + query).then((result) => {
      const match = result.data && result.data.results && result.data.results.length ? result.data.results[0] : null
      result.location = parseGoogleMapsLocation(match)
      return result
    })
  }

  const parseGoogleMapsLocation = (locationObj) => {
    let parsedLocation = {}

    if (locationObj) {
      parsedLocation = {
        longitude: locationObj.geometry.location.lng,
        latitude: locationObj.geometry.location.lat,
        line1: '', // Line 1 is set below as it requires a bit more work
        line2: '',
        city: getGoogleMapsAddressComponent(locationObj.address_components, 'locality'),
        state: getGoogleMapsAddressComponent(locationObj.address_components, 'administrative_area_level_1'),
        postcode: getGoogleMapsAddressComponent(locationObj.address_components, 'postal_code'),
        country: getGoogleMapsAddressComponent(locationObj.address_components, 'country')
      }

      const streetAddress = getGoogleMapsAddressComponent(locationObj.address_components, 'street_address')
      const streetNumber = getGoogleMapsAddressComponent(locationObj.address_components, 'street_number')
      const streetName = getGoogleMapsAddressComponent(locationObj.address_components, 'route')

      if (streetAddress) {
        parsedLocation.line1 = streetAddress
      }
      if (streetNumber && streetName) {
        parsedLocation.line1 = streetNumber + ' ' + streetName
      } else {
        parsedLocation.line1 = streetNumber || streetName
      }
    }

    return parsedLocation
  }

  const getGoogleMapsAddressComponent = (addressComponents, addressComponentName, getShortName = false) => {
    const length = addressComponents.length

    for (let i = 0; i < length; i++) {
      const addressComponent = addressComponents[i]
      if (addressComponent.types && addressComponent.types.indexOf(addressComponentName) > -1) {
        return getShortName ? addressComponent.short_name : addressComponent.long_name
      }
    }

    // if the code reaches this point it means we couldn't find a component with the provided addressComponentName
    return ''
  }

  return {
    geocode: googleMapsGeocode,
    reverseGeocode: googleMapsReverseGeocode
  }
})()

export default locationService
