import { useEffect } from 'react'
import { Paper, Button, Typography } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { visuallyHidden } from '@mui/utils'

import PageTitle from '../common/PageTitle'
import ProgressOverlay from '../common/ProgressOverlay'
import EmailFieldset from './EmailFieldset'
import ButtonWithProgress from '../common/ButtonWithProgress'
import BasicDialog from '../common/BasicDialog'

import intl from '../../helper/intl'
import validate from '../../helper/validate'

import accountService from '../../service/accountService'

import { useSelector, useDispatch } from 'react-redux'
import {
  accountEmailsChange,
  updateEmailStart,
  updateEmailSuccess,
  updateEmailFailure,
  deleteEmailAsync,
  showNewEmailDialog,
  hideNewEmailDialog,
  newEmailChange,
  createNewEmailAsync
} from '../../actions/accountEmailsAction'
import {
  openAppSnackbar,
  closeAppSnackbar,
  openAppDialog,
  closeAppDialog,
  openContactConfirmationDialog
} from '../../actions/notificationsAction'

import useStyles from './AccountEmailsPage.styles'

const AccountEmailsPage = () => {
  const companyData = useSelector((state) => state.auth.companyData)
  const accountId = useSelector((state) => state.auth.accountId)
  const emails = useSelector((state) => state.accountEmails.emails)
  const emailsLoading = useSelector((state) => state.accountEmails.emailsLoading)
  const updateEmailLoading = useSelector((state) => state.accountEmails.updateEmailLoading)
  const deleteEmailLoading = useSelector((state) => state.accountEmails.deleteEmailLoading)
  const newEmailDialogIsOpen = useSelector((state) => state.accountEmails.newEmailDialogIsOpen)
  const newEmail = useSelector((state) => state.accountEmails.newEmail)
  const createNewEmailLoading = useSelector((state) => state.accountEmails.createNewEmailLoading)
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    document.title = intl.translate('account_emails_page__title') + ' | ' + (companyData.portal_title || intl.translate('app_header__title'))
  }, [companyData])

  const shouldFieldsetBeDisabled = (emailId) => {
    // We'll disable a fieldset if the email of that fieldset is being updated or deleted.
    return (updateEmailLoading && updateEmailLoading === emailId) || (deleteEmailLoading && deleteEmailLoading === emailId)
  }

  const onEmailsChange = (emailId, email) => {
    const newEmails = []
    const numEmails = emails.length
    for (let i = 0; i < numEmails; i++) {
      const _email = emailId === emails[i].id ? email : emails[i]
      newEmails.push({ ..._email })
    }
    dispatch(accountEmailsChange({ emails: newEmails }))
  }

  const onUpdateEmailFormSubmit = (event, email) => {
    event.preventDefault()

    const emailError = getEmailError(email)
    if (emailError) {
      dispatch(openAppSnackbar({ message: emailError }))
      return false
    }

    updateEmail(accountId, email)
  }

  const updateEmail = (_accountId, _email) => {
    dispatch(updateEmailStart({ id: _email.id }))

    _email.email = _email.email.toLowerCase() // Email addresses should be all lowercase

    accountService
      .updateEmail({ accountId: _accountId, email: _email })
      .then((result) => {
        dispatch(updateEmailSuccess())
        dispatch(openAppSnackbar({ message: intl.translate('account_emails_page__update_success') }))
        onEmailsChange(_email.id, result.data)
      })
      .catch((error) => {
        dispatch(updateEmailFailure({ error: intl.translate('account_emails_page__update_error') }))
        dispatch(
          openAppSnackbar({
            message: intl.getServerError(error.response.data) || intl.translate('account_emails_page__update_error'),
            actionText: intl.translate('general__retry'),
            actionFunction: () => {
              dispatch(closeAppSnackbar())
              updateEmail(_accountId, _email)
            }
          })
        )
      })
  }

  const showDeleteEmailDialog = (emailId) => {
    dispatch(
      openAppDialog({
        title: intl.translate('account_emails_page__delete_modal_title'),
        description: intl.translate('account_emails_page__delete_modal_description'),
        actionText: intl.translate('account_emails_page__delete_modal_confirm'),
        actionFunction: () => onDeleteEmail(emailId),
        autoFocusButton: 'cancel'
      })
    )
  }

  const onDeleteEmail = (emailId) => {
    dispatch(closeAppDialog())
    dispatch(deleteEmailAsync({ accountId, id: emailId }))
  }

  const onCreateEmailFormSubmit = (event) => {
    event.preventDefault()

    const emailError = getEmailError(newEmail)
    if (emailError) {
      dispatch(openAppSnackbar({ message: emailError }))
      return false
    }

    dispatch(createNewEmailAsync({ accountId, email: newEmail }))
  }

  const getEmailError = (emailObj) => {
    if (!emailObj.alias) {
      return intl.translate('account_emails_page__empty_alias_error')
    } else if (!validate.isValidEmail(emailObj.email)) {
      return intl.translate('general__invalid_email')
    } else {
      return null
    }
  }

  return (
    <>
      <Paper className="_centered_container_ _position_relative_">
        <ProgressOverlay hidden={!emailsLoading} />

        <PageTitle>{intl.translate('account_emails_page__title')}</PageTitle>

        {emails.map((email, index) => (
          <form key={index} autoComplete="off" onSubmit={(event) => onUpdateEmailFormSubmit(event, email)}>
            <EmailFieldset
              id={email.id}
              className={classes['email-fieldset']}
              legend={email.alias || '-'}
              disabled={shouldFieldsetBeDisabled(email.id)}
              emailConfirmed={email.emailConfirmed}
              onConfirmEmail={() => dispatch(openContactConfirmationDialog({ email: email.email }))}
              alias={email.alias}
              email={email.email}
              onChange={(email) => onEmailsChange(email.id, email)}
              onDelete={() => showDeleteEmailDialog(email.id)}
              showDeleteProgress={deleteEmailLoading && deleteEmailLoading === email.id}
            />

            <div className="_centered_btn_container_">
              <ButtonWithProgress
                type="submit"
                id={'update-email-' + email.id + '-btn'}
                className={classes['update-btn']}
                showProgress={updateEmailLoading && updateEmailLoading === email.id}
                disabled={shouldFieldsetBeDisabled(email.id)}
                variant="contained"
                color={window.app.theme.palette.button ? 'button' : 'primary'}
                size="large"
              >
                {intl.translate('account_emails_page__update_btn')}
                <Typography style={visuallyHidden}> {email.alias}</Typography>
              </ButtonWithProgress>
            </div>
          </form>
        ))}

        <div className="_centered_btn_container_">
          <Button
            className={classes['create-btn']}
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<AddIcon />}
            onClick={() => dispatch(showNewEmailDialog())}
          >
            {intl.translate('account_emails_page__create_btn')}
          </Button>
        </div>
      </Paper>

      <BasicDialog
        id="new-email-dialog"
        isOpen={newEmailDialogIsOpen}
        title={intl.translate('account_emails_page__create_modal_title')}
        description=""
        actionButtons={[
          {
            content: intl.translate('account_emails_page__create_modal_cancel'),
            onClick: () => dispatch(hideNewEmailDialog())
          },
          {
            id: 'create-new-email-btn',
            content: intl.translate('account_emails_page__create_modal_confirm'),
            type: 'submit',
            form: 'new-email-form',
            showProgress: createNewEmailLoading
          }
        ]}
        onClose={() => dispatch(hideNewEmailDialog())}
      >
        <form id="new-email-form" autoComplete="off" onSubmit={onCreateEmailFormSubmit}>
          <EmailFieldset
            id="new-email"
            legend={newEmail.alias || '-'}
            autoFocus={true}
            disabled={createNewEmailLoading}
            alias={newEmail.alias}
            email={newEmail.email}
            onChange={(email) => dispatch(newEmailChange({ email }))}
          />
        </form>
      </BasicDialog>
    </>
  )
}

export default AccountEmailsPage
