import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      flex: '0 1 100%',
      margin: '0',
      padding: '0',
      borderTop: '1px solid ' + theme.palette.divider
      // backgroundColor: theme.palette.background.default
    },
    menu: {
      display: 'block',
      margin: '0',
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(3),
      textAlign: 'right',

      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(2)
      }
    },
    'menu-item': {
      display: 'inline-block',
      padding: '0',
      paddingLeft: theme.spacing(2),
      listStyleType: 'none',
      fontSize: theme.typography.body1.fontSize,

      '& a': {
        display: 'block',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
      },

      [theme.breakpoints.down('md')]: {
        display: 'block',
        paddingLeft: '0',
        textAlign: 'center'
      }
    }
  }),
  { name: 'app-footer-' }
)

export default useStyles
