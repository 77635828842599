import {
  Person as PersonIcon,
  Mail as MailIcon,
  Phone as PhoneIcon,
  Room as RoomIcon,
  SupervisorAccount as SupervisorAccountIcon,
  Notifications as NotificationsIcon,
  Cancel as CancelIcon,
  Help as HelpIcon,
  ExitToApp as ExitToAppIcon,
  ListAlt as ListAltIcon,
  LockReset as LockResetIcon
} from '@mui/icons-material'

import serviceConfig from '../service/config'

import intl from './intl'

const utils = (() => {
  let _permissions = []

  // Used to initialize permissions array with values coming from the server
  const setPermissions = function (permissions) {
    _permissions = permissions
  }

  // Used to check if a permission is enabled (assuming permissions have been initialized)
  const checkPermission = function (permissionId) {
    return _permissions.indexOf(permissionId) > -1
  }

  // Checks if an object of any kind is empty
  const isEmpty = (obj) => {
    if (obj && obj.constructor === Array) {
      // Array objects
      return obj.length === 0
    } else if (obj && obj.constructor === Date) {
      // Date objects
      return !obj
    } else if (obj && typeof obj === 'object') {
      // Other objects
      return Object.keys(obj).length === 0
    } else {
      // non-objects
      return !obj
    }
  }

  // Checks if an object is deep empty
  // An object is deep empty if all of its properties are empty
  const isDeepEmpty = (obj) => {
    if (isEmpty(obj)) {
      // An empty object is also deep empty
      return true
    } else if (typeof obj === 'object') {
      // This is a non-empty object. We have to check if its properties are empty.
      for (const key in obj) {
        if (!isDeepEmpty(obj[key])) {
          return false
        }
      }

      return true
    } else {
      // This is a non-empty variable (non-object)
      return false
    }
  }

  // Gets a query parameter from the URL given its name
  const getUrlParam = (paramName) => {
    const url = window.location.href
    const matchParam = url.match(new RegExp(paramName + '=[-a-zA-Z0-9_+%.]*', 'g'))

    return matchParam ? matchParam[0].replace(paramName + '=', '') : null
  }

  // Encodes a string to be used as a URL parameter.
  // For example, The string "I say 'hello'" will be encoded as "I%20say%20%27hello%27"
  const encodeUrlParam = (param) => {
    let encodedParam = window.encodeURIComponent(param)

    // Additional encoding that encodeURIComponent doesn't do
    encodedParam = encodedParam.replace(/'/g, '%27')

    return encodedParam
  }

  // Decodes a string encoded as a URL parameter.
  // For example, the string "I%20say%20%27hello%27" will be decoded as "I say 'hello'"
  const decodeUrlParam = (param) => {
    const decodedParam = window.decodeURIComponent(param)
    return decodedParam
  }

  const getMenuItems = (urlBasename, accountId, isFromSSO) => {
    const menuItems = []
    
    if (accountId) {
      menuItems.push({ id: 'account', name: 'navigation_menu__account', url: urlBasename, menu: true, footer: false, icon: PersonIcon })
      if (utils.checkPermission('emails_page_view')) {
        menuItems.push({
          id: 'emails',
          name: 'navigation_menu__emails',
          url: urlBasename + '/emails',
          menu: true,
          footer: false,
          icon: MailIcon
        })
      }
      if (utils.checkPermission('phones_page_view')) {
        menuItems.push({
          id: 'phones',
          name: 'navigation_menu__phones',
          url: urlBasename + '/telephones',
          menu: true,
          footer: false,
          icon: PhoneIcon
        })
      }
      if (utils.checkPermission('locations_page_view')) {
        menuItems.push({
          id: 'locations',
          name: 'navigation_menu__locations',
          url: urlBasename + '/locations',
          menu: true,
          footer: false,
          icon: RoomIcon
        })
      }
      if (utils.checkPermission('relatives_page_view')) {
        menuItems.push({
          id: 'individuals',
          name: 'navigation_menu__individuals',
          url: urlBasename + '/relatives',
          menu: true,
          footer: false,
          icon: SupervisorAccountIcon
        })
      }
      if (utils.checkPermission('notifications_page_view')) {
        menuItems.push({
          id: 'notifications',
          name: 'navigation_menu__notifications',
          url: urlBasename + '/notifications',
          menu: true,
          footer: false,
          icon: NotificationsIcon
        })
      }
      if (utils.checkPermission('unsubscribe_page_view')) {
        menuItems.push({
          id: 'deregister',
          name: 'navigation_menu__deregister',
          url: urlBasename + '/unsubscribe',
          menu: true,
          footer: false,
          icon: CancelIcon
        })
      }
      if (utils.checkPermission('faq_page_view')) {
        menuItems.push({
          id: 'faq',
          name: 'navigation_menu__faq',
          url: serviceConfig.getFaqUrl(),
          menu: true,
          footer: false,
          icon: HelpIcon
        })
      }
      menuItems.push({ id: 'sitemap', name: 'navigation_menu__sitemap', url: urlBasename + '/sitemap', menu: false, footer: true })
      if (isFromSSO !== true) {
        menuItems.push({ id: 'change-password', name: 'navigation_menu__change_password', url: urlBasename + '/change-password', menu: true, footer: false, icon: LockResetIcon })
      }
      // menuItems.push({ id: 'accessibility-statement', name: 'navigation_menu__accessibility_statement', url: urlBasename+'/accessibility-statement', menu: false, footer: true });
      menuItems.push({ id: 'logout', name: 'navigation_menu__log_out', menu: true, footer: false, icon: ExitToAppIcon }) // url is empty because there's no "Log out" page
    } else {
      menuItems.push({ id: 'login', name: 'navigation_menu__login', url: urlBasename, menu: true, footer: false, icon: ExitToAppIcon })
      if (utils.checkPermission('register_page_view')) {
        menuItems.push({
          id: 'register',
          name: 'navigation_menu__register',
          url: urlBasename + '/register',
          menu: true,
          footer: false,
          icon: ListAltIcon
        })
      }
      menuItems.push({
        id: 'new-password',
        name: 'navigation_menu__new_password',
        url: urlBasename + '/new-password',
        menu: false,
        footer: false
      })

      if (utils.checkPermission('faq_page_view')) {
        menuItems.push({
          id: 'faq',
          name: 'navigation_menu__faq',
          url: serviceConfig.getFaqUrl(),
          menu: true,
          footer: false,
          icon: HelpIcon
        })
      }
      menuItems.push({ id: 'sitemap', name: 'navigation_menu__sitemap', url: urlBasename + '/sitemap', menu: false, footer: true })
      // menuItems.push({ id: 'accessibility-statement', name: 'navigation_menu__accessibility_statement', url: urlBasename+'/accessibility-statement', menu: false, footer: true });
    }

    return menuItems
  }

  const getMissingAccountData = (companyData, { accountDetailsData, accountLocationsData }) => {
    const missingAccountData = [];

    const missingAccountDetails = [];
    if (companyData.portal_registration_name_mandatory && !accountDetailsData.firstName.trim()) {
      missingAccountDetails.push(intl.translate('account_details_page__first_name'));
    }
    if (companyData.portal_registration_name_mandatory && !accountDetailsData.lastName.trim()) {
      missingAccountDetails.push(intl.translate('account_details_page__last_name'));
    }
    if (companyData.portal_registration_language_mandatory && !accountDetailsData.language) {
      missingAccountDetails.push(intl.translate('account_details_page__language'));
    }

    if (missingAccountDetails.length > 0) {
      missingAccountData.push({
        id: 'account',  // this ID should match the ID of the corresponding menu item
        text: missingAccountDetails.join(', '),
        url: ''
      })
    }

    if (companyData.location_mandatory && !accountLocationsData.length) {
      missingAccountData.push({
        id: 'locations',
        text: intl.translate('app_header__notifier_locations'),
        url: '/locations'
      });
    }

    return missingAccountData;
  }

  // Generates a URL to get an asset from AWS, given the asset name (for example, image.png)
  const generateAWSUrl = (assetName) => {
    let awsUrl = ''
    const companyName = window.companyData ? window.companyData.name : ''

    if (assetName && companyName) {
      const assetNameIsUrl = assetName.indexOf('/resources/') > -1 && assetName.indexOf('/portal/') > -1

      if (assetNameIsUrl) {
        // If the asset name already contains the path to /resources/ and /portal/, then use it as the URL
        awsUrl = assetName
      } else {
        // Build the URL
        // The line below is a trick so we make requests to DEV from local environment
        const origin = window.location.hostname === 'localhost' ? 'https://lradmnsdev.genasys.com' : ''
        awsUrl = origin + '/resources/' + companyName + '/portal/' + assetName
      }
    }

    return awsUrl
  }

  const generateRandomString = () => {
    return Math.random().toString(36).substring(2);
  }

  return {
    isEmpty,
    isDeepEmpty,
    getUrlParam,
    encodeUrlParam,
    decodeUrlParam,
    getMenuItems,
    getMissingAccountData,
    generateAWSUrl,
    generateRandomString,
    setPermissions,
    checkPermission
  }
})()

export default utils
