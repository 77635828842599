import { useEffect, useState } from 'react'
import { Paper, Grid, Typography, Link } from '@mui/material'
import PageTitle from '../common/PageTitle'
import TextInput from '../common/TextInput'
import PhoneNumberInput from '../common/PhoneNumberInput'
import ButtonWithProgress from '../common/ButtonWithProgress'
import Separator from '../common/Separator'

import serviceConfig from '../../service/config'
import authService from '../../service/authService'
import intl from '../../helper/intl'
import validate from '../../helper/validate'

import { useSelector, useDispatch } from 'react-redux'
import { loginStart, loginSuccess, loginFailure, loginCredentialsChange, mfaUserDataChange } from '../../actions/authAction'
import { openAppSnackbar, closeAppSnackbar } from '../../actions/notificationsAction'

import useStyles from './LoginPage.styles'
import utils from '../../helper/utils'

const LoginPage = () => {
  const companyData = useSelector((state) => state.auth.companyData)
  const loginLoading = useSelector((state) => state.auth.loginLoading)
  const loginCredentials = useSelector((state) => state.auth.loginCredentials)
  const urlBasename = useSelector((state) => state.intl.urlBasename)
  const mfaUserData = useSelector((state) => state.auth.mfaUserData)
  const dispatch = useDispatch()
  const classes = useStyles()
  const [mfaCode, setMfaCode] = useState('')
  const [mfaStep, setMfaStep] = useState('login')  // 'login', 'send-email', 'send-sms', 'verify-email', 'verify-sms' 
  const [tempAccountId, setTempAccountId] = useState('');

  useEffect(() => {
    document.title = intl.translate('login_page__title') + ' | ' + (companyData.portal_title || intl.translate('app_header__title'))

    if (companyData.name === 'Longueuil' && !loginCredentials.countryCode) {
      onLoginCredentialsChange('countryCode', 'CA') // Initialize countryCode = +1 for Longueuil
    }
  }, [companyData])

  const getPhoneNumberError = (countryCode) => {
    if (countryCode === 'CA' || countryCode === 'US') {
      return intl.translate('general__invalid_north_american_phone_number')
    }

    return intl.translate('general__invalid_phone_number')
  }

  const onLoginCredentialsChange = (paramName, paramValue) => {
    const newLoginCredentials = { ...loginCredentials }
    newLoginCredentials[paramName] = paramValue
    dispatch(loginCredentialsChange({ credentials: newLoginCredentials }))
  }

  const onLoginFormSubmit = (event) => {
    event.preventDefault()

    dispatch(loginStart())

    if (loginCredentials.phoneNumber && !loginCredentials.countryCode) {
      dispatch(loginFailure({ error: intl.translate('general__empty_phone_prefix_error') }))
      dispatch(
        openAppSnackbar({
          message: intl.translate('general__empty_phone_prefix_error'),
          actionText: intl.translate('general__retry'),
          actionFunction: () => {
            dispatch(closeAppSnackbar())
            document.getElementById('login-page--submit-btn').click() // re-submit the form instead of calling onLoginFormSubmit
          }
        })
      )
      return false
    }

    authService
      .login(loginCredentials)
      .then((result) => {
        const loginData = {
          accountId: result.data.id,
          accessToken: result.data.accessToken,
          refreshToken: result.data.refreshToken,
        };
        setTempAccountId(result.data.id);

        dispatch(mfaUserDataChange({
          email: result.data.email || '',
          countryCode: result.data.country_code ? result.data.country_code.code || '' : '',
          phoneNumber: result.data.number || '',
        }));

        if (companyData.mfa_sms_login) {
          if (result.data.number && result.data.country_code && result.data.country_code.code) {
            sendMfaConfirmationCode({
              phoneNumber: result.data.number,
              countryCode: result.data.country_code.code,
              onSent: () => {
                dispatch(loginSuccess({ loginData, accountId: '' }));
              },
              onSentError: () => {
                dispatch(loginFailure({ error: intl.translate('login_page__login_error') }))
              }
            })
          } else {
            setMfaStep('send-sms');
            dispatch(loginSuccess({ loginData, accountId: '' }));
          }
        } else if (companyData.mfa_email_login) {
          if (result.data.email) {
            sendMfaConfirmationCode({
              email: result.data.email,
              onSent: () => {
                dispatch(loginSuccess({ loginData, accountId: '' }));
              },
              onSentError: () => {
                dispatch(loginFailure({ error: intl.translate('login_page__login_error') }))
              }
            })
          } else {
            setMfaStep('send-email');
            dispatch(loginSuccess({ loginData, accountId: '' }));
          }
          
        } else {
          dispatch(loginSuccess(loginData));
        }
      })
      .catch((error) => {
        dispatch(loginFailure({ error: intl.translate('login_page__login_error') }))
        dispatch(
          openAppSnackbar({
            message: intl.getServerError(error?.response?.data) || intl.translate('login_page__login_error'),
            actionText: intl.translate('general__retry'),
            actionFunction: () => {
              dispatch(closeAppSnackbar())
              document.getElementById('login-page--submit-btn').click() // re-submit the form instead of calling onLoginFormSubmit
            }
          })
        )
      })
  }

  const onMfaPhoneFormSubmit = (event) => {
    event.preventDefault()

    if (!(mfaUserData.phoneNumber && mfaUserData.countryCode)) {
      return false
    }

    sendMfaConfirmationCode({
      phoneNumber: mfaUserData.phoneNumber,
      countryCode: mfaUserData.countryCode
    })
  }

  const onMfaEmailFormSubmit = (event) => {
    event.preventDefault()

    if (!mfaUserData.email) {
      return false
    }

    sendMfaConfirmationCode({
      email: mfaUserData.email
    })
  }

  const onMfaCodeFormSubmit = (event) => {
    event.preventDefault()

    if (!mfaCode || mfaCode.length !== 6) {
      return false
    }

    const verifyData = { mfaCode };
    if (mfaStep === 'verify-email') {
      verifyData.email = mfaUserData.email;
    } else if (mfaStep === 'verify-sms') {
      verifyData.countryCode = mfaUserData.countryCode
      verifyData.phoneNumber = mfaUserData.phoneNumber
    }

    authService.verifyMfaCode(verifyData)
    .then((result) => {
      if (result.status === 200) {
        dispatch(loginSuccess({ accountId: tempAccountId }));
      } else {
        dispatch(openAppSnackbar({ message: intl.translate('login_page__mfa_error_verifying_code') }));
      }
    })
    .catch((error) => {
      if (error?.response?.data?.message) {
        dispatch(openAppSnackbar({ message: error.response.data.message }));
      } else {
        dispatch(openAppSnackbar({ message: intl.translate('login_page__mfa_error_verifying_code') }));
      }
    })

  }

  const sendMfaConfirmationCode = ({ email, countryCode, phoneNumber, onSent, onSentError }) => {
    authService.requestMfaCode({ email, countryCode, phoneNumber })
    .then((result) => {
      if (result.status === 200) {
        setMfaStep(email ? 'verify-email' : 'verify-sms');
        
        if (onSent && typeof onSent === 'function') {
          onSent();
        }
      } else {
        dispatch(openAppSnackbar({ message: intl.translate('login_page__mfa_error_sending_code') }));
        if (onSentError && typeof onSentError === 'function') {
          onSentError()
        }
      }
    })
    .catch((error) => {
      if (error?.response?.data?.message) {
        dispatch(openAppSnackbar({ message: error.response.data.message }));
      } else {
        dispatch(openAppSnackbar({ message: intl.translate('login_page__mfa_error_sending_code') }));
      }
      if (onSentError && typeof onSentError === 'function') {
        onSentError()
      }
    });
  }

  const onResendMfaConfirmationCode = () => {
    const resendParams = {
      onSent: () => {
        dispatch(openAppSnackbar({ message: intl.translate('login_page__mfa_code_resent_alert') }))
      }
    }
    if (mfaStep === 'verify-email') {
      resendParams.email = mfaUserData.email;
    } else if (mfaStep === 'verify-sms') {
      resendParams.countryCode = mfaUserData.countryCode;
      resendParams.phoneNumber = mfaUserData.phoneNumber;
    }
    sendMfaConfirmationCode(resendParams);
  }

  const PUBLIC_PATH = window.__env__ ? (window.__env__.PUBLIC_PATH || '') : '';

  return (
    <>
      <Paper className="_centered_container_">
        <PageTitle>
          {(mfaStep === 'login') && intl.translate('login_page__title')}
          {(mfaStep === 'send-email') && intl.translate('login_page__mfa_enter_email')}
          {(mfaStep === 'send-sms') && intl.translate('login_page__mfa_enter_phone')}
          {(mfaStep === 'verify-email' || mfaStep === 'verify-sms') && intl.translate('login_page__mfa_enter_code')}
        </PageTitle>

        {(mfaStep === 'login') && (
          <form onSubmit={onLoginFormSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {companyData.salm_url && (
                  <div className="_centered_btn_container_">
                    <Typography variant="body1" className={classes['sso-wrapper']}>
                      <Link
                        id="login-page--sso-link"
                        href={companyData.salm_url}
                        color={window.app.theme.palette.link ? 'link.main' : 'primary'}
                      >
                        {intl.translate('login_page__sso_button')}
                      </Link>
                    </Typography>
                  </div>
                )}

                {utils.checkPermission('login_email_view') && (
                  <>
                    <TextInput
                      id="login-page--email"
                      label={intl.translate('general__email_label')}
                      disabled={loginLoading}
                      name="username"
                      autoComplete="username"
                      value={loginCredentials.email}
                      onChange={(event) => onLoginCredentialsChange('email', event.target.value)}
                      isValid={(value) => (value ? validate.isValidEmail(value) : true)}
                      errorMessage={intl.translate('general__invalid_email')}
                    />
                    {(utils.checkPermission('login_external_id_view') || utils.checkPermission('login_phone_number_view')) && (
                      <Separator className={classes.separator} text={intl.translate('login_page__separator')} />
                    )}
                  </>
                )}

                {utils.checkPermission('login_phone_number_view') && (
                  <PhoneNumberInput
                    id="login-page--phone-number"
                    label={intl.translate('general__phone_number_label')}
                    disabled={loginLoading}
                    autoComplete="phone-number"
                    value={loginCredentials.phoneNumber}
                    countryCodeValue={loginCredentials.countryCode}
                    onPhoneNumberChange={(event) => onLoginCredentialsChange('phoneNumber', event.target.value)}
                    onCountryCodeChange={(event) => onLoginCredentialsChange('countryCode', event.target.value)}
                    isValid={(value) => (value ? validate.isValidPhoneNumber(value, { countryCode: loginCredentials.countryCode }) : true)}
                    errorMessage={getPhoneNumberError(loginCredentials.countryCode)}
                  />
                )}
                {utils.checkPermission('login_external_id_view') && utils.checkPermission('login_phone_number_view') && (
                  <Separator className={classes.separator} text={intl.translate('login_page__separator')} />
                )}

                {utils.checkPermission('login_external_id_view') && (
                  <TextInput
                    id="login-page--external-id"
                    label={companyData.portal_external_id_label || intl.translate('login_page__external_id_label')}
                    disabled={loginLoading}
                    name="external-id"
                    autoComplete="external-id"
                    value={loginCredentials.externalId}
                    onChange={(event) => onLoginCredentialsChange('externalId', event.target.value)}
                  />
                )}
              </Grid>

              {utils.checkPermission('login_password_view') && (
                <Grid item xs={12}>
                  <TextInput
                    id="login-page--password"
                    type="password"
                    label={intl.translate('general__password_label')}
                    disabled={loginLoading}
                    name='password'
                    // autoComplete="current-password"
                    value={loginCredentials.password}
                    onChange={(event) => onLoginCredentialsChange('password', event.target.value)}
                    isValid={(password) => !!password}
                    errorMessage={intl.translate('general__empty_password')}
                  />
                  <Typography variant="caption" color={window.app.theme.palette.link ? 'link.main' : 'primary'}>
                    <Link
                      href={`${PUBLIC_PATH}${urlBasename}/new-password?dc=${utils.generateRandomString()}`}
                      color="inherit"
                    >
                      {intl.translate('login_page__link_to_reset_password')}
                    </Link>
                  </Typography>
                </Grid>
              )}

              {utils.checkPermission('login_button_view') && (
                <Grid item xs={12}>
                  <div className="_centered_btn_container_">
                    <ButtonWithProgress type="submit" id="login-page--submit-btn" showProgress={loginLoading} size="large">
                      {intl.translate('login_page__submit_btn')}
                    </ButtonWithProgress>
                  </div>
                </Grid>
              )}

              {utils.checkPermission('register_page_view') && (
                <Grid item xs={12}>
                  <Typography
                    className={classes['link-to-register']}
                    align="center"
                    variant="body1"
                    color={window.app.theme.palette.button ? 'link.main' : 'primary'}
                  >
                    <span>{intl.translate('login_page__prompt_to_register')} </span>
                    <Link
                      href={`${PUBLIC_PATH}${urlBasename}/register?dc=${utils.generateRandomString()}`}
                      color="inherit"
                    >
                      {intl.translate('login_page__link_to_register')}
                    </Link>
                  </Typography>
                </Grid>
              )}

              {utils.checkPermission('faq_page_view') && (
                <Grid item xs={12}>
                  <Typography
                    className={classes['link-to-faq']}
                    align="center"
                    variant="body1"
                    color={window.app.theme.palette.button ? 'link.main' : 'primary'}
                  >
                    <Link href={serviceConfig.getFaqUrl()} target="_blank" color="inherit">
                      {intl.translate('login_page__link_to_faq')}
                    </Link>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </form>
        )}

        {(mfaStep === 'send-email') && (
          <form onSubmit={onMfaEmailFormSubmit} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="_centered_btn_container_">
                  <Typography variant="body1" className={classes['sso-wrapper']}>
                    {intl.translate('login_page__mfa_email_subtitle')}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="login-page--mfa-email"
                  label={intl.translate('general__email_label')}
                  name="mfa-email"
                  autoComplete="mfa-email"
                  value={mfaUserData.email}
                  onChange={(event) => dispatch(mfaUserDataChange({ email: event.target.value }))}
                  isValid={(value) => (value ? validate.isValidEmail(value) : true)}
                  errorMessage={intl.translate('general__invalid_email')}
                />
              </Grid>
              <Grid item xs={12}>
                <div className="_centered_btn_container_">
                  <ButtonWithProgress type="submit" showProgress={false} disabled={!mfaUserData.email} size="large">
                    {intl.translate('login_page__mfa_send_code')}
                  </ButtonWithProgress>
                </div>
                <div className="_centered_btn_container_">
                  <Link
                    component="button"
                    variant="body1"
                    sx={{ mt: 2 }}
                    color={window.app.theme.palette.button ? 'link.main' : 'primary'}
                    onClick={() => { window.location.reload() }}
                  >
                    {intl.translate('password_request_page__link_to_login')}
                  </Link>
                </div>
              </Grid>
            </Grid>
          </form>
        )}

        {(mfaStep === 'send-sms') && (
          <form onSubmit={onMfaPhoneFormSubmit} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="_centered_btn_container_">
                  <Typography variant="body1" className={classes['sso-wrapper']}>
                    {intl.translate('login_page__mfa_phone_subtitle')}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <PhoneNumberInput
                  id="login-page--mfa-phone-number"
                  label={intl.translate('general__phone_number_label')}
                  autoComplete="phone-number"
                  value={mfaUserData.phoneNumber}
                  countryCodeValue={mfaUserData.countryCode}
                  onPhoneNumberChange={(event) => dispatch(mfaUserDataChange({ countryCode: mfaUserData.countryCode, phoneNumber: event.target.value }))}
                  onCountryCodeChange={(event) => dispatch(mfaUserDataChange({ countryCode: event.target.value, phoneNumber: mfaUserData.phoneNumber }))}
                  isValid={(value) => (value ? validate.isValidPhoneNumber(value, { countryCode: mfaUserData.countryCode }) : true)}
                  errorMessage={getPhoneNumberError(mfaUserData.countryCode)}
                />
                {companyData.mfa_email_login && (
                  <Typography align="right" variant="body1" color={window.app.theme.palette.button ? 'link.main' : 'primary'}>
                    <Link
                      component="button"
                      color="inherit"
                      onClick={() => {
                        if (mfaUserData.email) {
                          sendMfaConfirmationCode({ email: mfaUserData.email });
                        } else {
                          setMfaStep('send-email');
                        }
                      }}
                    >
                      {intl.translate('login_page__mfa_link_to_email')}
                    </Link>
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <div className="_centered_btn_container_">
                  <ButtonWithProgress type="submit" showProgress={false} disabled={!mfaUserData.countryCode || !mfaUserData.phoneNumber} size="large">
                    {intl.translate('login_page__mfa_send_code')}
                  </ButtonWithProgress>
                </div>
                <div className="_centered_btn_container_">
                  <Link
                    component="button"
                    variant="body1"
                    sx={{ mt: 2 }}
                    color={window.app.theme.palette.button ? 'link.main' : 'primary'}
                    onClick={() => { window.location.reload() }}
                  >
                    {intl.translate('password_request_page__link_to_login')}
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12}>
                <p className={classes['p-mfa-authorize']}>{intl.translate('login_page__mfa_authorize_paragraph')}</p>
              </Grid>
            </Grid>
          </form>
        )}

        {(mfaStep === 'verify-email' || mfaStep === 'verify-sms') && (
          <form onSubmit={onMfaCodeFormSubmit} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="_centered_btn_container_">
                  <Typography variant="body1" className={classes['sso-wrapper']}>
                    {(mfaStep === 'verify-email') ? (
                      intl.translate('login_page__mfa_email_code_subtitle', { email: mfaUserData.email })
                    ) : (
                      intl.translate('login_page__mfa_phone_code_subtitle')
                    )}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="login-page--mfa-code"
                  label={intl.translate('login_page__mfa_code_field_tooltip')}
                  name="mfa-code"
                  autoComplete="mfa-code"
                  value={mfaCode}
                  onChange={(event) => setMfaCode(event.target.value)}
                  isValid={(value) => (value ? value.length === 6 : true)}
                  errorMessage={intl.translate('login_page__mfa_code_field_error')}
                />
                <Typography align="right" variant="body1" color={window.app.theme.palette.button ? 'link.main' : 'primary'}>
                  <Link
                    component="button"
                    color="inherit"
                    onClick={onResendMfaConfirmationCode}
                  >
                    {intl.translate('login_page__mfa_didnt_get_link')}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className="_centered_btn_container_">
                  <ButtonWithProgress type="submit" showProgress={false} disabled={mfaCode?.length !== 6} size="large">
                    {intl.translate('login_page__mfa_verify_code')}
                  </ButtonWithProgress>
                </div>
                <div className="_centered_btn_container_">
                  <Link
                    component="button"
                    variant="body1"
                    sx={{ mt: 2 }}
                    color={window.app.theme.palette.button ? 'link.main' : 'primary'}
                    onClick={() => { window.location.reload() }}
                  >
                    {intl.translate('password_request_page__link_to_login')}
                  </Link>
                </div>
              </Grid>
            </Grid>
          </form>
        )}
      </Paper>

      {/* companyData.salm_url &&
        <Paper className={classes['sso-wrapper'] + ' _centered_container_'}>
          <PageTitle className={classes['sso-title']}>
            {intl.translate('login_page__sso_title')}
          </PageTitle>
          <div className="_centered_btn_container_">
            <ButtonWithProgress
              id="login-page--sso-btn"
              showProgress={loginLoading}
              size="large"
              href={companyData.salm_url}>
              {intl.translate('login_page__sso_button')}
            </ButtonWithProgress>
          </div>
        </Paper>
      */}
    </>
  )
}

export default LoginPage
