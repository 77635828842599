import { useEffect } from 'react'

import { Link } from '@mui/material'

import AppHeader from '../AppHeader'
import NavigationMenu from '../NavigationMenu'
import AppBody from '../AppBody'
import AppFooter from '../AppFooter'
import AppNotifications from '../AppNotifications'

import { useSelector, useDispatch } from 'react-redux'
import { companyDataChange } from '../../actions/authAction'
import { availableLanguagesChange } from '../../actions/intlAction'

// Styles configuration
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles'
import { StylesProvider, jssPreset } from '@mui/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import rtlStylisPlugin from 'stylis-plugin-rtl'
import { create as createJss } from 'jss'
import rtlJssPlugin from 'jss-rtl'

import intl from '../../helper/intl'
import utils from '../../helper/utils'

import useStyles from './App.styles'

// This is a wrapper of the app that contains style logic
const App = () => {
  const languageDirection = useSelector((state) => state.intl.languageDirection)

  const dispatch = useDispatch()

  const theme = window.app ? window.app.theme || {} : {} // The theme is loaded via a script on the index.html
  theme.direction = languageDirection // Set the direction of the theme depending on the language
  theme.backgroundImageSrc = utils.generateAWSUrl(theme.backgroundImageSrc)
  theme.mainLogoImageSrc = utils.generateAWSUrl(theme.mainLogoImageSrc)
  theme.faviconImageSrc = utils.generateAWSUrl(theme.faviconImageSrc)

  useEffect(() => {
    // Set company data and available languages to initialize the application
    if (window.availableLanguages && window.availableLanguages.length) {
      dispatch(availableLanguagesChange({ languages: window.availableLanguages }))
    }
    if (window.companyData) {
      dispatch(companyDataChange({ data: window.companyData }))
    }
    if (theme.faviconImageSrc) {
      const faviconElement = document.getElementById('favicon')
      faviconElement.href = theme.faviconImageSrc
    }
  }, [dispatch])

  // Configuration to support RTL languages
  const jss = { plugins: jssPreset().plugins }
  if (languageDirection === 'rtl') {
    jss.plugins.push(rtlJssPlugin())
  }
  const cacheRtl = createCache({
    key: languageDirection === 'rtl' ? 'cssrtl' : 'cssltr',
    prepend: true,
    stylisPlugins: languageDirection === 'rtl' && [rtlStylisPlugin]
  })

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider
        theme={createTheme({
          ...theme,
          components: {
            MuiFormLabel: {
              styleOverrides: {
                asterisk: { color: 'red' }
              }
            }
          }
        })}
      >
        <CacheProvider value={cacheRtl}>
          <StylesProvider jss={createJss(jss)}>
            {/* Reset browser styles to prevent inconsistencies */}
            <CssBaseline />

            {/* Content of the app */}
            <AppContent />
          </StylesProvider>
        </CacheProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

// This is the actual content of the app
const AppContent = () => {
  const accountId = useSelector((state) => state.auth.accountId)
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Link
        className={classes['skip-to-content-link']}
        href="#app-body--content"
        variant="body1"
        color={window.app.theme.palette.link ? 'link.main' : 'primary'}
      >
        {intl.translate('general__skip_to_content_link')}
      </Link>
      <AppHeader />
      {accountId && <NavigationMenu />}
      <AppBody />
      <AppFooter />
      <AppNotifications />
    </div>
  )
}

export default App
