import countryCodeOptions from '../data/country_codes.json'

const intl = (() => {
  const _defaultLanguage = 'en'
  const _defaultTranslations = require('../data/translations.' + _defaultLanguage + '.json')
  // let _language = _defaultLanguage - var seems to not be using anywhere
  let _translations = _defaultTranslations

  // List of main languages that use right-to-left scripts
  const _rtlLanguages = {
    ar: true, // Arabic
    dv: true, // Divehi, Dhivehi, Maldivian
    ff: true, // Fula, Fulah, Pulaar, Pular
    he: true, // Hebrew
    ku: true, // Kurdish
    fa: true, // Persian (Farsi)
    ur: true // Urdu
  }

  // Returns the default language
  const getDefaultLanguage = () => {
    return _defaultLanguage
  }

  const getBrowserLanguage = () => {
    const browserLanguage = navigator.language
    if (typeof browserLanguage === 'string') {
      return browserLanguage.substr(0, 2) // navigator.language returns a locale (example "en-US"). So extract the language portion.
    } else {
      return null
    }
  }

  // Returns the language code of the URL. If there's no language code, it returns null.
  const getUrlLanguage = () => {
    const pathname = window.location.pathname || ''

    let localeRegex = /^\/\w{2}(\/|$)/
    let replaceRegex = /\//g
    if (window.__env__ && window.__env__.PUBLIC_PATH) {
      localeRegex = new RegExp('^' + window.__env__.PUBLIC_PATH + '/\\w{2}(/|$)')
      replaceRegex = new RegExp(window.__env__.PUBLIC_PATH + '|/', 'g')
    }

    return pathname.match(localeRegex) ? pathname.match(localeRegex)[0].replace(replaceRegex, '').toLowerCase() : null
  }

  // Returns the language direction (right-to-left or left-to-right) of a given language
  const getLanguageDirection = (lang) => {
    return _rtlLanguages[lang] ? 'rtl' : 'ltr'
  }

  // Returns true if translations are available for a given language, false otherwise
  const areTranslationsAvailable = (lang) => {
    let availableTranslations = null

    try {
      availableTranslations = require('../data/translations.' + lang + '.json')
    } catch (error) {
      // We don't have translations for the given language
      return false
    }

    return !!availableTranslations
  }

  // Sets the language and its set of translations on the site. Returns true if the operation was successful, false otherwise.
  const setLanguageAndTranslations = (lang) => {
    try {
      _translations = require('../data/translations.' + lang + '.json')
    } catch (error) {
      // We don't have translations for the given language
      return false
    }

    // _language = lang - var seems to not be using anywhere
    document.documentElement.lang = lang // set "lang" attribute of <html>
    document.documentElement.dir = getLanguageDirection(lang) // set "dir" attribute of <html>
    document.body.dir = getLanguageDirection(lang) // set "dir" attribute of <body>

    // If the code reaches this point it means that we could set the translations properly
    return true
  }

  // Returns the translation of a text given its translation ID
  const translate = (translationId, translationVariables) => {
    const variables = translationVariables || {}

    // If the translation doesn't exist for the selected language, look for the default translation
    let translation = _translations[translationId] || _defaultTranslations[translationId]

    if (typeof translation !== 'string') {
      return null // there's no translation available
    }

    // Check for variables in the translation
    const variableNames = translation.match(/\$\{[a-zA-z0-9_]+\}/g)
    if (variableNames) {
      const length = variableNames.length
      for (let i = 0; i < length; i++) {
        const variableNameWithBraces = variableNames[i]
        const variableName = variableNameWithBraces.replace(/^\$\{|\}$/g, '')
        const variableValue = variables[variableName] || ''
        translation = translation.replace(variableNameWithBraces, variableValue)
      }
    }

    return translation
  }

  const translateToLang = (translationId, translationVariables, lang, fallback) => {
    const _currentTranslations = _translations
    try {
      _translations = require('../data/translations.' + lang + '.json')
      const translatedValue = _translations[translationId] || fallback || translate(translationId, translationVariables)
      _translations = _currentTranslations
      return translatedValue
    } catch (_er) {
      console.log('Falla', fallback)
      return fallback ?? translate(translationId, translationVariables)
    }
  }

  // Gets the dial code of a country, given its country code
  const getDialCodeByCountryCode = (countryCode) => {
    const length = countryCodeOptions.length
    for (let i = 0; i < length; i++) {
      const country = countryCodeOptions[i]
      if (country.code === countryCode) {
        return country.dial_code
      }
    }
  }

  // Gets the country code of a country, given its dial code
  const getCountryCodeByDialCode = (dialCode) => {
    const length = countryCodeOptions.length
    for (let i = 0; i < length; i++) {
      const country = countryCodeOptions[i]
      if (country.dial_code === dialCode) {
        return country.code
      }
    }
  }

  const getServerError = (errorResponse) => {
    if (errorResponse) {
      return intl.translate('server_error__e' + errorResponse.internalCode) || errorResponse.message || null
    }

    return null
  }

  return {
    getDefaultLanguage,
    getBrowserLanguage,
    getUrlLanguage,
    getLanguageDirection,
    areTranslationsAvailable,
    setLanguageAndTranslations,
    translate,
    translateToLang,
    getDialCodeByCountryCode,
    getCountryCodeByDialCode,
    getServerError
  }
})()

export default intl
