import { Button, CircularProgress } from '@mui/material'

import useStyles from './ButtonWithProgress.styles'

const ButtonWithProgress = ({ className, showProgress, disabled, children, ...otherProperties }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper + (className ? ' ' + className : '')}>
      {/* ...otherProperties contains any other property not declared in this component */}
      {/* So it means that if some additional properties are added to this component, they will be pasted on the button */}
      {/* and they will override all properties declared before it if they have the same name */}
      {/* e.g. 'color' can be overriden, but 'disabled' can't */}
      <Button
        className={classes.button}
        variant="contained"
        color={window.app.theme.palette.button ? 'button' : 'primary'}
        {...otherProperties}
        disabled={showProgress || disabled /* showProgress overrides the disabled state of the button */}
      >
        {children}
      </Button>
      <CircularProgress className={classes.progress + (showProgress ? '' : ' _hidden_')} size={36} aria-hidden="true" />
    </div>
  )
}

export default ButtonWithProgress
