import { TOGGLE_NAVIGATION_MENU } from '../actions/navigationMenuAction'

const initialState = {
  navigationMenuIsOpen: false
}

const reducer = (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case TOGGLE_NAVIGATION_MENU: {
      return {
        ...state,
        navigationMenuIsOpen: !state.navigationMenuIsOpen
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
