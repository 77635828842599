import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      textAlign: 'center'
    }
  }),
  { name: 'page-title-' }
)

export default useStyles
