import { useEffect } from 'react'
import { Paper, Typography, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import PageTitle from '../common/PageTitle'

import intl from '../../helper/intl'
import utils from '../../helper/utils'
import serviceConfig from '../../service/config'
import { useSelector } from 'react-redux'

import useStyles from './SitemapPage.styles'

const SitemapPage = () => {
  const accountId = useSelector((state) => state.auth.accountId)
  const companyData = useSelector((state) => state.auth.companyData)
  const mfaPending = useSelector((state) => state.auth.mfaPending)
  const urlBasename = useSelector((state) => state.intl.urlBasename)
  const classes = useStyles()

  useEffect(() => {
    document.title = intl.translate('sitemap_page__title') + ' | ' + (companyData.portal_title || intl.translate('app_header__title'))
  }, [companyData])

  const menuItems = utils.getMenuItems(urlBasename, accountId, companyData, mfaPending)
  menuItems.sort((itemA, itemB) => {
    // Sort menu items alphabetically
    const itemAName = intl.translate(itemA.name)
    const itemBName = intl.translate(itemB.name)

    if (itemAName < itemBName) {
      return -1
    }

    if (itemAName > itemBName) {
      return 1
    }

    return 0
  })

  const PUBLIC_PATH = window.__env__ ? (window.__env__.PUBLIC_PATH || '') : '';

  return (
    <Paper className="_centered_container_">
      <PageTitle className={classes.title}>{intl.translate('sitemap_page__title')}</PageTitle>

      {menuItems.map((menuItem, index) =>
        menuItem.id !== 'sitemap' && menuItem.id !== 'logout' ? (
          <Typography key={menuItem.id} variant="body1" color={window.app.theme.palette.link ? 'link.main' : 'primary'}>
            {(menuItem.id === 'faq') && (
              <Link className={classes.link} href={serviceConfig.getFaqUrl()} color="inherit">
                {intl.translate(menuItem.name)}
              </Link>
            )}
            {(menuItem.id === 'register' || menuItem.id === 'new-password') && (
              <Link
                className={classes.link}
                href={`${PUBLIC_PATH}${menuItem.url}?dc=${utils.generateRandomString()}`}
                color="inherit"
              >
                {intl.translate(menuItem.name)}
              </Link>
            )}
            {(menuItem.id !== 'faq' && menuItem.id !== 'register' && menuItem.id !== 'new-password') && (
              <Link className={classes.link} component={RouterLink} to={menuItem.url} color="inherit">
                {intl.translate(menuItem.name)}
              </Link>
            )}
          </Typography>
        ) : null
      )}
    </Paper>
  )
}

export default SitemapPage
