import { Box, Grid, Paper, Typography } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { useEffect } from 'react'

import AutocompleteInput from '../common/AutocompleteInput'
import ButtonWithProgress from '../common/ButtonWithProgress'
import CheckboxInput from '../common/CheckboxInput'
import EmailInput from '../common/EmailInput'
import NativeSelectInput from '../common/NativeSelectInput'
import PageTitle from '../common/PageTitle'
import PhoneNumberInput from '../common/PhoneNumberInput'
import ProgressOverlay from '../common/ProgressOverlay'
import TextInput from '../common/TextInput'

import intl from '../../helper/intl'
import utils from '../../helper/utils'
import validate from '../../helper/validate'
import accountService from '../../service/accountService'

import { useDispatch, useSelector } from 'react-redux'
import {
  accountDetailsChange,
  updateAccountDetailsFailure,
  updateAccountDetailsStart,
  updateAccountDetailsSuccess
} from '../../actions/accountDetailsAction'
import { closeAppSnackbar, openAppSnackbar, openContactConfirmationDialog } from '../../actions/notificationsAction'

const noValue = intl.translateToLang('general__emptyText_select');

const AccountDetailsPage = () => {
  const accountId = useSelector((state) => state.auth.accountId)
  const companyData = useSelector((state) => state.auth.companyData)
  const details = useSelector((state) => state.accountDetails.details)
  const customFields = useSelector((state) => state.accountDetails.customFields)
  const detailsLoading = useSelector((state) => state.accountDetails.detailsLoading)
  const updateDetailsLoading = useSelector((state) => state.accountDetails.updateDetailsLoading)
  const availableLanguages = useSelector((state) => state.intl.availableLanguages)

  const dispatch = useDispatch()
  const availableLanguagesTranslated = []
  for (let i = 0; i < availableLanguages.length; i++) {
    const language = availableLanguages[i]
    let name = intl.translate('app_header__language_select_' + (language.value ? language.value.toLowerCase() : '')) || language.name
    if (language) {
      name = intl.translateToLang('app_header__language_select_current', null, language.value.toLowerCase(), language.name) || name
    }
    availableLanguagesTranslated.push({
      name, // : intl.translate('app_header__language_select_' + (language.value ? language.value.toLowerCase() : '')) || language.name,
      value: language.value
    })
  }

  useEffect(() => {
    document.title = intl.translate('account_details_page__title') + ' | ' + (companyData.portal_title || intl.translate('app_header__title'))
  }, [companyData])

  useEffect(() => {
    if (companyData.name === 'Longueuil' && !detailsLoading && !details.countryCode) {
      onDetailsChange('countryCode', 'CA') // Initialize countryCode = +1 for Longueuil
    }
  }, [companyData, detailsLoading])

  const getPhoneNumberError = (countryCode) => {
    if (countryCode === 'CA' || countryCode === 'US') {
      return intl.translate('general__invalid_north_american_phone_number')
    }

    return intl.translate('general__invalid_phone_number')
  }

  const onDetailsChange = (paramName, paramValue) => {
    const newDetails = { ...details }
    newDetails[paramName] = paramValue
    dispatch(accountDetailsChange({ details: newDetails }))
  }

  const onCustomFieldsChange = (fieldId, fieldValue) => {
    const newCustomFields = []
    const numberOfCustomFields = customFields.length
    for (let i = 0; i < numberOfCustomFields; i++) {
      const field = customFields[i]
      if (fieldId === field.id) {        
        field.value = fieldValue === noValue ? '' : fieldValue;
      }
      newCustomFields.push(field)
    }
    dispatch(accountDetailsChange({ customFields: newCustomFields }))
  }

  const onFormSubmit = (event) => {
    event.preventDefault()

    dispatch(updateAccountDetailsStart())

    // Check that the phone number, if filled out, has countryCode
    if (details.phoneNumber && !details.countryCode) {
      dispatch(updateAccountDetailsFailure({ error: intl.translate('general__empty_phone_prefix_error') }))
      dispatch(
        openAppSnackbar({
          message: intl.translate('general__empty_phone_prefix_error'),
          actionText: intl.translate('general__retry'),
          actionFunction: () => {
            dispatch(closeAppSnackbar())
            document.getElementById('account-details-page--update-btn').click()
          }
        })
      )
      return false
    }

    updateDetails(event);
  }

  const updateDetails = (event) => {
    dispatch(updateAccountDetailsStart())

    details.email = details.email.toLowerCase() // Email addresses should be all lowercase

    let errorMessage = '';
    if (companyData.portal_registration_name_mandatory === true && !details.firstName.trim()) {
      errorMessage = intl.translate("account_individuals_page__empty_first_name_error");
    } else if (companyData.portal_registration_name_mandatory === true && !details.lastName.trim()) {
      errorMessage = intl.translate("account_individuals_page__empty_last_name_error");
    }

    if (errorMessage) {
      dispatch(updateAccountDetailsFailure({ error: errorMessage }));
      dispatch(openAppSnackbar({ message: errorMessage }));
      return false;
    }

    accountService
      .updateDetails({ accountId, details, customFields })
      .then((result) => {
        dispatch(updateAccountDetailsSuccess())
        dispatch(openAppSnackbar({ message: intl.translate('account_details_page__update_success') }))

        const newDetails = { ...details }
        newDetails.emailConfirmed = result.data.emailConfirmed
        newDetails.phoneNumberConfirmed = result.data.phoneNumberConfirmed
        /* [GEM-2658] - This is disabled as it apparently conflicts with the "Verify" button flow
        if (newDetails.phoneNumberConfirmed === false && newDetails.countryCode && newDetails.phoneNumber) {
          dispatch(
            openContactConfirmationDialog({ phoneNumber: intl.getDialCodeByCountryCode(newDetails.countryCode) + newDetails.phoneNumber })
          )
        }
        */
        dispatch(accountDetailsChange({ details: newDetails }))
      })
      .catch((error) => {
        dispatch(updateAccountDetailsFailure({ error: intl.translate('account_details_page__update_error') }))
        dispatch(
          openAppSnackbar({
            message: intl.getServerError(error.response.data) || intl.translate('account_details_page__update_error'),
            actionText: intl.translate('general__retry'),
            actionFunction: () => {
              dispatch(closeAppSnackbar())
              document.getElementById('account-details-page--update-btn').click()
            }
          })
        )
      })
  }

  return (
    <Paper className="_centered_container_ _position_relative_">
      <ProgressOverlay hidden={!detailsLoading} />

      <PageTitle>{intl.translate('account_details_page__title')}</PageTitle>

      {/* Form to change account details EXCEPT the password */}
      <form autoComplete="off" onSubmit={onFormSubmit}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <TextInput
              id="account-details-page--first-name"
              label={intl.translate('account_details_page__first_name')}
              disabled={updateDetailsLoading}
              required={companyData.portal_registration_name_mandatory === true}
              value={details.firstName}
              onChange={(event) => onDetailsChange('firstName', event.target.value)}
              isValid={(value) => companyData.portal_registration_name_mandatory ? !!value.trim() : true}
              errorMessage={intl.translate('general__empty_field_error')}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextInput
              id="account-details-page--last-name"
              label={intl.translate('account_details_page__last_name')}
              disabled={updateDetailsLoading}
              required={companyData.portal_registration_name_mandatory === true}
              value={details.lastName}
              onChange={(event) => onDetailsChange('lastName', event.target.value)}
              isValid={(value) => companyData.portal_registration_name_mandatory ? !!value.trim() : true}
              errorMessage={intl.translate('general__empty_field_error')}
            />
          </Grid>
          <Grid item xs={12}>
            <NativeSelectInput
              id="account-details-page--language"
              label={intl.translate('account_details_page__language')}
              disabled={updateDetailsLoading}
              required={companyData.portal_registration_language_mandatory === true}
              name="account-details-page--language"
              value={details.language}
              options={availableLanguagesTranslated}
              variant="outlined"
              fullWidth={true}
              margin="normal"
              onChange={(event) => onDetailsChange('language', event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            {utils.checkPermission('account_email_view') && (
              <EmailInput
                id="account-details-page--email"
                label={intl.translate('account_details_page__email')}
                disabled={updateDetailsLoading}
                readOnly={!utils.checkPermission('account_email_modify')}
                confirmed={details.emailConfirmed}
                onConfirm={() => dispatch(openContactConfirmationDialog({ email: details.email }))}
                value={details.email}
                onChange={(event) => onDetailsChange('email', event.target.value)}
                isValid={(value) => (value ? validate.isValidEmail(value) : true)}
                errorMessage={intl.translate('general__invalid_email')}
              />
            )}
          </Grid>
          {utils.checkPermission('account_phone_number_view') && (
            <Grid item xs={12}>
              <PhoneNumberInput
                id="account-details-page--phone-number"
                label={intl.translate('account_details_page__phone_number')}
                disabled={updateDetailsLoading}
                confirmed={details.phoneNumberConfirmed}
                onConfirm={() => {
                  const phoneNumber = intl.getDialCodeByCountryCode(details.countryCode) + details.phoneNumber
                  dispatch(openContactConfirmationDialog({ phoneNumber, countryCode: details.countryCode, phoneNumberConfirmed: false }))
                }}
                value={details.phoneNumber}
                readOnly={!utils.checkPermission('account_phone_number_modify')}
                countryCodeValue={details.countryCode}
                onPhoneNumberChange={(event) => onDetailsChange('phoneNumber', event.target.value)}
                onCountryCodeChange={(event) => onDetailsChange('countryCode', event.target.value)}
                isValid={(value) => (value ? validate.isValidPhoneNumber(value, { countryCode: details.countryCode }) : true)}
                errorMessage={getPhoneNumberError(details.countryCode)}
              />
              <CheckboxInput
                id={'account-details-page--phone-number-sms'}
                label={intl.translate('general__phone_number_sms')}
                disabled={updateDetailsLoading}
                checked={details.phoneNumberSms}
                onChange={(event) => onDetailsChange('phoneNumberSms', event.target.checked)}
              />
            </Grid>
          )}

          {utils.checkPermission('account_external_id_view') && (
            <Grid item xs={12}>
              <TextInput
                id="account-details-page--external-id"
                label={companyData.portal_external_id_label || intl.translate('login_page__external_id_label')}
                disabled={updateDetailsLoading}
                readOnly={true}
                value={details.externalId}
                onChange={(event) => onDetailsChange('externalId', event.target.value)}
              />
            </Grid>
          )}

          {customFields &&
            customFields.map((field) => (
              <Grid item xs={12} key={field.id}>
                {field.field_type.id === 1 && ( // id = 1 --> TextInput
                  <TextInput
                    id={'account-details-page--' + field.id}
                    label={<span lang="en">{field.name}</span>}
                    disabled={updateDetailsLoading}
                    value={field.value || ''}
                    onChange={(event) => onCustomFieldsChange(field.id, event.target.value)}
                  />
                )}
                {field.field_type.id === 2 && ( // id = 2 --> AutocompleteInput
                  <AutocompleteInput
                    id={'account-details-page--' + field.id}
                    label={<span lang="en">{field.name}</span>}
                    disabled={updateDetailsLoading}
                    value={field.value || ''}
                    options={field.previous_values || []}
                    onChange={(value) => onCustomFieldsChange(field.id, value)}
                  />
                )}
                {field.field_type.id === 3 && ( // id = 3 --> NativeSelectInput
                  <NativeSelectInput
                    id={'account-details-page--' + field.id}
                    label={<span lang="en">{field.name}</span>}
                    disabled={updateDetailsLoading}
                    value={field.value || noValue}
                    options={field.previous_values? [noValue, ...field.previous_values] : []}
                    getOptionValue={(option) => option}
                    renderOption={(option) => option}
                    variant="outlined"
                    fullWidth={true}
                    margin="normal"
                    onChange={(event) => onCustomFieldsChange(field.id, event.target.value)}
                  />
                )}
              </Grid>
            ))}

          <Grid item xs={12}>
            <Box className="_centered_btn_container_" paddingBottom={4}>
              <ButtonWithProgress
                type="submit"
                id="account-details-page--update-btn"
                showProgress={updateDetailsLoading}
                variant="contained"
                color={window.app.theme.palette.button ? 'button' : 'primary'}
                size="large"
              >
                {intl.translate('account_details_page__submit_btn')}
                <Typography style={visuallyHidden}> {intl.translate('account_details_page__account_details')}</Typography>
              </ButtonWithProgress>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
}

export default AccountDetailsPage
