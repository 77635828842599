import { AppBar, Toolbar, Typography, IconButton, Tooltip } from '@mui/material'
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material'
import NativeSelectInput from '../common/NativeSelectInput'

import intl from '../../helper/intl'

import { useSelector, useDispatch } from 'react-redux'
import { languageChange } from '../../actions/intlAction'
import { toggleNavigationMenu } from '../../actions/navigationMenuAction'

import useStyles from './AppHeader.styles'

const AppHeader = () => {
  const companyData = useSelector((state) => state.auth.companyData)
  const accountId = useSelector((state) => state.auth.accountId)
  const navigationMenuIsOpen = useSelector((state) => state.navigationMenu.navigationMenuIsOpen)
  const language = useSelector((state) => state.intl.language)
  const availableLanguages = useSelector((state) => state.intl.availableLanguages)
  const availableLanguagesLoading = useSelector((state) => state.intl.availableLanguagesLoading)
  const dispatch = useDispatch()
  const classes = useStyles()

  // Display only languages with translations
  const availableLanguagesWithTranslations = []
  for (let i = 0; i < availableLanguages.length; i++) {
    if (intl.areTranslationsAvailable(availableLanguages[i].value)) {
      const language = availableLanguages[i]
      let name = intl.translate('app_header__language_select_' + (language.value ? language.value.toLowerCase() : '')) || language.name
      if (language) {
        name = intl.translateToLang('app_header__language_select_current', null, language.value.toLowerCase(), language.name) || name
      }
      availableLanguagesWithTranslations.push({
        name,
        value: language.value
      })
    }
  }

  const onLanguageChange = (event) => {
    const newLanguage = event.target.value

    dispatch(languageChange({ language: newLanguage }))
  }

  return (
    <AppBar className={classes.wrapper}>
      <Toolbar className={classes.toolbar}>
        {accountId && (
          <Tooltip title={intl.translate(navigationMenuIsOpen ? 'app_header__close_menu' : 'app_header__open_menu')}>
            <IconButton
              className={classes['menu-button']}
              aria-label={intl.translate(navigationMenuIsOpen ? 'app_header__close_menu' : 'app_header__open_menu')}
              onClick={() => dispatch(toggleNavigationMenu())}
            >
              {navigationMenuIsOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </Tooltip>
        )}

        <Typography className={classes.title} variant="h6">
          {window.app && window.app.theme && window.app.theme.mainLogoImageSrc && (
            <img className={classes.logo} src={window.app.theme.mainLogoImageSrc} alt={intl.translate('app_header__logo')} />
          )}
          <span className={classes['title-text']}>{companyData.portal_title || intl.translate('app_header__title')}</span>
        </Typography>

        {availableLanguagesWithTranslations.length > 1 && (
          <Tooltip title={intl.translate('app_header__language_select')}>
            <div>
              <NativeSelectInput
                id="app-header--language-select"
                className={classes['language-select']}
                classes={{ 'html-value': 'language-select-html-value' }}
                labelVisuallyHidden={true}
                name="app-header--language-select"
                value={language}
                renderValue={(value) => value.toUpperCase()}
                options={availableLanguagesWithTranslations}
                disabled={availableLanguagesLoading}
                variant="outlined"
                onChange={onLanguageChange}
                dir={'ltr'}
              />
            </div>
          </Tooltip>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default AppHeader
