import { useState, useEffect, createElement, useMemo } from 'react'
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Link, Tooltip, Badge } from '@mui/material'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import intl from '../../helper/intl'
import utils from '../../helper/utils'

import { useSelector, useDispatch } from 'react-redux'
import { toggleNavigationMenu } from '../../actions/navigationMenuAction'
import { logout } from '../../actions/authAction'

import useStyles from './NavigationMenu.styles'

const NavigationMenu = () => {
  const companyData = useSelector((state) => state.auth.companyData)
  const accountId = useSelector((state) => state.auth.accountId)
  const accountDetailsData = useSelector((state) => state.accountDetails.details)
  const accountLocationsData = useSelector((state) => state.accountLocations.locations)
  // const languageDirection = useSelector(state => state.intl.languageDirection)
  const [focusedMenuItemId, setFocusedMenuItemId] = useState('')
  const [hoveredMenuItemId, setHoveredMenuItemId] = useState('')
  const navigationMenuIsOpen = useSelector((state) => state.navigationMenu.navigationMenuIsOpen)
  const urlBasename = useSelector((state) => state.intl.urlBasename)
  const location = useLocation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const menuItems = useMemo(() => {
    return utils.getMenuItems(urlBasename, accountId, accountDetailsData.isFromSSO)
  }, [urlBasename, accountId, accountDetailsData])

  const missingAccountData = useMemo(() => {
    return utils.getMissingAccountData(companyData, { accountDetailsData, accountLocationsData })
  }, [companyData, accountDetailsData, accountLocationsData])

  useEffect(() => {
    if (!navigationMenuIsOpen) {
      // Hide tooltips if navigation menu gets closed (prevents showing tooltips on mobile)
      setFocusedMenuItemId('')
      setHoveredMenuItemId('')
    }
  }, [navigationMenuIsOpen])

  const onMenuItemClick = (menuItemId) => {
    if (menuItemId === 'logout') {
      // Logout menu item was clicked
      dispatch(logout())
      window.location.reload()
    } else {
      // If any other menu item is clicked, we close the menu before redirecting to another page
      if (navigationMenuIsOpen) {
        dispatch(toggleNavigationMenu())
      }
    }
  }

  const getMenuItemComponent = (menuItemId) => {
    switch (menuItemId) {
      case 'faq':
        return Link
      case 'logout':
        return undefined
      default:
        return RouterLink
    }
  }

  const canShowMenuItemTooltip = (menuItemId) => {
    if (!navigationMenuIsOpen) {
      if (menuItemId === focusedMenuItemId || menuItemId === hoveredMenuItemId) {
        return true
      }
    }
    return false
  }

  return (
    <>
      <Drawer
        variant="permanent"
        className={classes.drawer + ' ' + (navigationMenuIsOpen ? classes['drawer-open'] : classes['drawer-closed'])}
        classes={{ paper: classes['drawer-paper'] + ' ' + (navigationMenuIsOpen ? classes['drawer-open'] : classes['drawer-closed']) }}
        anchor="left"
        open={navigationMenuIsOpen}
      >
        {/* This element adds a spacing equal to the height of the header, so that the top part of the body isn't hidden. */}
        <div className={classes['header-spacing']} aria-hidden="true"></div>

        <div className={classes['drawer-list-wrapper']}>
          <List className={classes['drawer-list']} component="nav">
            {menuItems.map((menuItem, index) =>
              menuItem.menu ? (
                <Tooltip key={menuItem.id} title={intl.translate(menuItem.name)} open={canShowMenuItemTooltip(menuItem.id)}>
                  <ListItem
                    className={classes.item}
                    button
                    selected={location.pathname === menuItem.url}
                    component={getMenuItemComponent(menuItem.id)}
                    to={menuItem.id === 'faq' ? undefined : menuItem.url}
                    href={menuItem.id === 'faq' ? menuItem.url : undefined}
                    target={menuItem.id === 'faq' ? '_blank' : undefined}
                    onClick={() => onMenuItemClick(menuItem.id)}
                    onMouseEnter={() => setHoveredMenuItemId(menuItem.id)}
                    onMouseLeave={() => {
                      setHoveredMenuItemId('')
                      setFocusedMenuItemId('')
                    }}
                    onFocus={() => setFocusedMenuItemId(menuItem.id)}
                    onBlur={() => setFocusedMenuItemId('')}
                  >
                    <Badge
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                      }}
                      color="secondary"
                      variant="dot"
                      invisible={!missingAccountData.find((missing) => missing.id === menuItem.id)}
                    >
                      <ListItemIcon>{menuItem.icon ? createElement(menuItem.icon) : null}</ListItemIcon>
                    </Badge>
                    <ListItemText className={classes['item-text']} primary={intl.translate(menuItem.name)} />
                  </ListItem>
                </Tooltip>
              ) : null
            )}
          </List>
        </div>
      </Drawer>

      {/* This <div> acts as an overlay on mobile to prevent clicking on the app body when the menu is open */}
      <div
        className={classes['drawer-overlay'] + (navigationMenuIsOpen ? ' ' + classes['drawer-overlay-active'] : '')}
        aria-hidden="true"
        onClick={() => dispatch(toggleNavigationMenu())}
      ></div>
    </>
  )
}

export default NavigationMenu
