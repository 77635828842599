import { Typography } from '@mui/material'

import useStyles from './PageTitle.styles'

const PageTitle = ({ className, children, ...otherProperties }) => {
  const classes = useStyles()

  return (
    <Typography className={classes.wrapper + (className ? ' ' + className : '')} variant="h4" gutterBottom={true} {...otherProperties}>
      {children}
    </Typography>
  )
}

export default PageTitle
